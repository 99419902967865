import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import categoryStore from '../../../../../../stores/categoryStore';
import cupomStore from '../../../../../../stores/cupomStore';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';

const CategorySelectedPromotion: React.FC = observer(() => {
    const { categoryListSelect } = categoryStore;
    const { cupomProps } = cupomStore;
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>();


    const onCheck = (checkedKeysValue: any) => {
        setCheckedKeys(checkedKeysValue as React.Key[]);
        cupomStore.updateCategory(checkedKeysValue);
    };


    useEffect(() => {
        if (cupomProps) {
            const categoryRestriction = cupomProps?.restrictions?.find((restriction: any) =>
                restriction["@class"] === "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction"
            );

            const uuids = categoryRestriction?.categories?.map((category: any) => category.uuid);
            setCheckedKeys(uuids);
        }
    }, [cupomProps]);

    return (
        <div className="pt-3">
            <Tree
                checkable
                checkStrictly
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                treeData={categoryListSelect}
            />
        </div>
    );
});

export default CategorySelectedPromotion;
