import { Typography } from 'antd';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import ReactQuill from 'react-quill';
import './input.scss';

//@ts-ignore
interface EditorTextComponentProps {
    label?: string;
    name: string;
    modules: any;
}

const defaultModules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }], // Títulos
        ['bold', 'italic', 'underline', 'strike'], // Formatações básicas
        [{ list: 'ordered' }, { list: 'bullet' }], // Listas
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }], // Alinhamento
        ['link', 'image'], // Links e imagens
        ['clean'], // Remove formatações
    ],
};

const EditorTextComponent: React.FC<EditorTextComponentProps> = ({ label, name, modules = defaultModules }) => (
    <div className="flex flex-col w-full input-container">
        {label && <Typography.Title level={5}>{label}</Typography.Title>}
        <Field name={name}>
            {({ field, form }: any) => (
                <>
                    <ReactQuill
                        value={field.value} // Valor gerenciado pelo Formik
                        onChange={(value) => form.setFieldValue(name, value)} // Atualiza o valor no Formik
                        modules={modules}
                        theme="snow"
                    />
                    <ErrorMessage name={name}>
                        {msg => <Typography.Text type="danger">{msg}</Typography.Text>}
                    </ErrorMessage>
                </>
            )}
        </Field>
    </div>
);

export default EditorTextComponent;
