import { observer } from 'mobx-react-lite';
import React, { Dispatch } from 'react';
import { useParams } from 'react-router-dom';
import productStore from '../../../../stores/productStore';
import AutocompleteComponent from '../../../atoms/AutocompleteComponent';
import InputComponent from '../../../atoms/InputComponet';
import TextAreaComponent from '../../../atoms/TextAreaComponent';
import ContentBox from '../../../molecules/ContentBox';
import Permission from '../../../molecules/Permission';
import { PermissionProducts } from '../../../../permissions/PermissionProducts';
import SwitchComponent from '../../../atoms/SwitchComponent';


interface ProdutoInformationProps {
    autocompleteOptions: any;
    setAutocompleteOptions: Dispatch<any>;
}

const ProdutoInformation: React.FC<ProdutoInformationProps> = observer(({ autocompleteOptions, setAutocompleteOptions }) => {
    const { uuid } = useParams<{ uuid: string }>();

    const handleSearch = async (searchText: string) => {
        if (searchText.length > 3) {
            try {
                const response = await productStore.searchProduct(searchText);
                setAutocompleteOptions(response);
            } catch (error) {
                console.error('Erro ao buscar cliente:', error);
            }
        }
    };

    return (
        <div>
            <ContentBox className="mb-4 p-6 rounded-none" title="Cadastro de Produto Variação" description='Tela para cadastro de Produto Variação' actions={
                <>
                    <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
                </>
            }>
                <div className="w-full flex flex-col gap-2">
                    <Permission permissionKeys={[PermissionProducts.GET]} inputType name="Produto">
                        <div className="flex gap-4 items-end">
                            <AutocompleteComponent
                                label="Produto Modelo"
                                name="baseProduct"
                                showImage={true}
                                placeholder="Digite o nome do Produto"
                                fetchOptions={handleSearch}
                                options={autocompleteOptions}
                            />
                        </div>
                    </Permission>

                    <div className="flex gap-4">
                        <InputComponent maxLength={100} showMaxLength={true} label="Nome" name="name" id="name" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent maxLength={100} showMaxLength={true} label="Nome Curto" name="shortName" id="shortName" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="EAN" name="barcode" id="barcode" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Link do vídeo" name="videoPath" id="videoPath" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent disabled label="Slug" name="slug" id="slug" />
                        <InputComponent label="Codigo SKU" name="skuCode" id="skuCode" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Meta Canônica" name="metaCanonical" id="metaCanonical" />
                        <InputComponent label="Meta Keywords" name="metaKeywords" id="metaKeywords" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Meta Title" name="metaTitle" id="metaTitle" />
                        <InputComponent label="Meta Description" name="metaDescription" id="metaDescription" />
                    </div>

                    <div className="flex gap-4">
                        <TextAreaComponent maxLength={350} showMaxLength={true} label="Descrição curta" name="shortDescription" id="shortDescription" />
                    </div>

                    <div className="flex gap-4">
                        <TextAreaComponent maxLength={1500} showMaxLength={true} label="Descrição completa" name="description" id="description" />
                    </div>
                </div>
            </ContentBox >
        </div >
    );
});

export default ProdutoInformation;