import { Button, Drawer, Form } from 'antd';
import { Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import productStore from '../../../../../stores/productStore';
import InputComponent from '../../../../atoms/InputComponet';
import { DrawerUploadUrlSchema } from './schema';


interface DrawerUploadUrlProps {
    isModalOpen?: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DrawerUploadUrl: React.FC<DrawerUploadUrlProps> = ({ isModalOpen, setIsModalOpen }) => {
    const handleSubmit = async (values: any) => {

        const createProps = {
            "@class": "br.com.stoom.kernel.model.persistence.File",
            file: {
                preview: values.path,
                path: values.path,
                thumbUrl: values.path
            },
            uuid: uuidv4(),
            new: true,
            metaTags: ['card'],
            ordering: values.ordering,
            path: values.path,
            name: values.path,
            size: 124,
        };

        productStore.addImageUrl(createProps);
        setIsModalOpen(false);
    };

    const initialValuesForm = {
        path: '',
        ordering: '',
    };

    return (
        <Formik validationSchema={DrawerUploadUrlSchema} initialValues={initialValuesForm}
            onSubmit={async (values, { resetForm }) => {
                await handleSubmit(values);
                resetForm()
            }}
            enableReinitialize>
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Cadastrar Imagem por URL"
                    width={540}
                    onClose={() => {
                        resetForm();
                        setIsModalOpen(false);
                    }}
                    open={isModalOpen}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                            <Button type="primary" onClick={submitForm}>
                                Cadastrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex flex-col gap-4">
                            <InputComponent size="middle" label="URL da Imagem" name="path" id="path" />
                            <InputComponent size="middle" label="Ordenação" name="ordering" id="ordering" />
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
};

export default DrawerUploadUrl;