export const mapErrorMessage = (errorMessage: string): string => {
    switch (errorMessage) {
        case " C�digo j� existente!":
            return "Código já existente!";
        case " Perfil existente na base de dados.":
            return "Perfil existente na base de dados.";
        case " J� existe uma cor cadastrada com o mesmo nome!":
            return "Já existe uma cor cadastrada com o mesmo nome!";
        default:
            return "Ocorreu um erro inesperado. Por favor, tente novamente.";
    }
};