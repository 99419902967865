import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionBanners } from '../../../permissions/PermissionBanners';
import { PermissionCategories } from '../../../permissions/PermissionCategories';
import { PermissionComponents } from '../../../permissions/PermissionComponents';
import bannerStore from '../../../stores/bannerStore';
import categoryStore from '../../../stores/categoryStore';
import channelStore from '../../../stores/channelStore';
import componentsStore from '../../../stores/componentsStore';
import { convertToDayjs, formattedDates } from '../../../utils/functions';
import { ComponentesSelect } from '../../../utils/selects';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import DatePickerRangeComponet from '../../atoms/DatePickerRangeComponet';
import InputComponet from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import SwitchComponent from '../../atoms/SwitchComponent';
import ContentBox from '../../molecules/ContentBox';
import { MobileIcon, WebIcon } from '../../molecules/IconComponent';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import PreviewComponents from '../../organisms/ComponentesHome';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchemaBanner, validationSchemaCategory } from './schema';
import { isBefore, parseISO, startOfDay } from 'date-fns';
import { message } from 'antd';

const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <Link to="/componentes">Listagem de Componente</Link>,
    },
    {
        title: <p>Cadastro de Componente</p>,
    },
]

const CreateComponents: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { componentProps, loading } = componentsStore;
    const { bannerListSelected } = bannerStore;
    const navigate = useNavigate();
    const { hasPermission } = useAuth();
    const { selectCategory } = categoryStore;
    const { selectChannelList } = channelStore;
    const [typeSelected, setTypeSelected] = useState<any>();
    const [selectedComponent, setSelectedComponent] = useState<any>();
    const [isMobile, setMobile] = useState<boolean>(false);

    const defaultparamsChannel = {
        size: 99999,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    useEffect(() => {
        const getInformation = async () => {
            if (uuid && hasPermission(PermissionComponents.GET_UUID)) {
                const response = await componentsStore.getComponentByUuid(uuid);

                if (!response.error) {
                    const select = {
                        value: response.type,
                        label: '',
                    }
                    setTypeSelected(select);
                    if (response.type === "BANNER_CAROUSEL") {
                        if (response.banners.length > 0) {
                            setSelectedComponent(response.banners[0]);
                        }
                    } else {
                        if (response.category) {
                            setSelectedComponent(response.category);
                        }
                    }
                }
            }

            if (hasPermission(PermissionBanners.GET)) {
                bannerStore.getList(defaultparamsChannel);
            }

            if (hasPermission(PermissionBanners.GET)) {
                channelStore.getList(defaultparamsChannel);
            }

            if (hasPermission(PermissionCategories.GET)) {
                categoryStore.getList();
            }
        };

        getInformation();

        return () => {
            componentsStore.reset();
        };
    }, [uuid]);

    const initialValues: any = {
        name: componentProps?.name || '',
        order: componentProps?.order || '',
        active: componentProps?.active || false,
        type: { value: componentProps?.type, label: '' },
        date: componentProps ? [convertToDayjs(componentProps?.minDate), convertToDayjs(componentProps?.maxDate)] : '',
        banners: componentProps ? { value: componentProps?.banners && componentProps?.banners[0]?.uuid, label: componentProps?.banners && componentProps?.banners[0]?.nameTag } : '',
        category: componentProps ? { value: componentProps?.category?.uuid, label: componentProps?.category?.categoryName } : '',
        mobile: { value: componentProps?.mobile === false ? 'Web' : 'Responsive', label: '' },
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: any) => {
        const formattedDate = formattedDates(values?.date, 'YYYY-MM-DD');
        const dateInitial = formattedDate[0];
        const dateFinal = formattedDate[1];

        const today = startOfDay(new Date());
        const finalDateParsed = parseISO(`${dateFinal}T23:59:59`);

        if (isBefore(finalDateParsed, today)) {
            message.error('A data final não pode ser menor que hoje.');
            return; // Interrompe o fluxo caso a validação falhe
        }

        let response;

        const createProps = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesComponentInputDTO",
            type: values?.type?.value,
            name: values?.name,
            order: 1,
            mobile: values?.mobile?.label === 'true' ? true : false,
            app: false,
            banners: [{
                uuid: values.banners.value
            }],
            category: {
                uuid: values.category.value
            },
            channel: {
                uuid: selectChannelList[0].value
            },
            minDate: `${dateInitial}T00:00:00`,
            maxDate: `${dateFinal}T23:59:59`,
            active: values.active,
            uuid: componentProps?.uuid,
        }


        if (values?.type?.value === "BANNER_CAROUSEL") {
            //@ts-ignore
            delete createProps.category
        }

        if (values?.type?.value === "PRODUCT_CAROUSEL") {
            //@ts-ignore
            delete createProps?.banners
        }

        if (uuid) {
            response = await componentsStore.editComponent(createProps, uuid);
        } else {
            response = await componentsStore.createComponet(createProps);
        }

        if (!response.error) listRouter();
    };


    const listRouter = () => {
        navigate(`/componentes`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionComponents.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Componente" description='Tela para cadastro de Componentes'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionComponents.POST, PermissionComponents.PUT, PermissionComponents.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={typeSelected?.value === "BANNER_CAROUSEL" ? validationSchemaBanner : validationSchemaCategory}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-6" title="Cadastro de Componente" description='Tela para cadastro de Componentes' actions={
                                <>
                                    <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
                                </>
                            }>
                                <div className="flex w-full gap-4 pb-4">
                                    <InputComponet label="Nome" name="name" id="name" />
                                    <SelectComponent onChange={(value, option) => setTypeSelected(option)} label="Tipo" name="type" options={ComponentesSelect} />
                                </div>

                                <div className="flex gap-4 grid grid-cols-12 pb-4">
                                    <div className="col-span-8">
                                        <DatePickerRangeComponet label="Data Inicial e Final" name="date" />
                                    </div>
                                    <div className="col-span-4">

                                        <Permission permissionKeys={[PermissionCategories.GET]} inputType name="Categoria">
                                            {typeSelected && typeSelected?.value === "PRODUCT_CAROUSEL" &&
                                                <SelectComponent onChange={(value, option) => setSelectedComponent(option?.option)} label="Categoria" name="category" options={selectCategory} />
                                            }
                                        </Permission>


                                        <Permission permissionKeys={[PermissionBanners.GET_UUID]} inputType name="Banners">
                                            {typeSelected && typeSelected?.value === "BANNER_CAROUSEL" &&
                                                <SelectComponent onChange={(value, option) => setSelectedComponent(option?.option)} label="Banners" name="banners" options={bannerListSelected} />
                                            }
                                        </Permission>
                                    </div>
                                </div>


                            </ContentBox>

                            <ContentBox className="mb-4 p-6" title="Preview dos Components" description='Visualização dos Componentes' actions={
                                <div className="flex gap-3">
                                    <div onClick={() => setMobile(true)} className={`p-2 cursor-pointer rounded ${isMobile ? 'border-2 border-blue-500' : 'border border-slate-200'}`}>
                                        <MobileIcon />
                                    </div>
                                    <div onClick={() => setMobile(false)} className={`p-2 cursor-pointer rounded ${!isMobile ? 'border-2 border-blue-500' : 'border border-slate-200'}`}>
                                        <WebIcon />
                                    </div>
                                </div>
                            }>

                                <PreviewComponents isMobile={isMobile} tag={typeSelected?.value === 'BANNER_CAROUSEL' ? selectedComponent?.tag : 'PRODUCT_CAROUSEL'} banners={typeSelected?.type === 'BANNER_CAROUSEL' ? selectedComponent?.files[0] : selectedComponent} bannerPage={false} />
                            </ContentBox>

                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionComponents.PUT, PermissionComponents.POST]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik >
                </Permission>
            }
        </>
    );
});

export default CreateComponents;