import { AppstoreOutlined, CreditCardOutlined, FileDoneOutlined, GoldOutlined, HomeOutlined, InboxOutlined, MailOutlined, RocketOutlined, SettingOutlined, SisternodeOutlined, TagsOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, type MenuProps } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PermissionAdministrator } from '../../../permissions/PermissionAdministrator';
import { PermissionAuthorityGroups } from '../../../permissions/PermissionAuthorityGroups';
import { PermissionBanners } from '../../../permissions/PermissionBanners';
import { PermissionCampaigns } from '../../../permissions/PermissionCampaigns';
import { PermissionCategories } from '../../../permissions/PermissionCategories';
import { PermissionChannel } from '../../../permissions/PermissionChannel';
import { PermissionColor } from '../../../permissions/PermissionColor';
import { PermissionConfig } from '../../../permissions/PermissionConfig';
import { PermissionCupons } from '../../../permissions/PermissionCupons';
import { PermissionCustomer } from '../../../permissions/PermissionCustomer';
import { PermissionEvaluations } from '../../../permissions/PermissionEvaluations';
import { PermissionHome } from '../../../permissions/PermissionHome';
import { PermissionInstitucional } from '../../../permissions/PermissionInstitucional';
import { PermissionMaterials } from '../../../permissions/PermissionMaterials';
import { PermissionOrder } from '../../../permissions/PermissionOrder';
import { PermissionPayment } from '../../../permissions/PermissionPayment';
import { PermissionProducts } from '../../../permissions/PermissionProducts';
import { PermissionProperties } from '../../../permissions/PermissionProperties';
import { PermissionReports } from '../../../permissions/PermissionReports';
import { PermissionShipping } from '../../../permissions/PermissionShipping';
import { PermissionSize } from '../../../permissions/PermissionSize';
import { PermissionStamps } from '../../../permissions/PermissionStamps';
import { PermissionSubChannel } from '../../../permissions/PermissionSubChannel';
import { PermissionTemplateEmail } from '../../../permissions/PermissionTemplateEmail';
import { PermissionVoltage } from '../../../permissions/PermissionVoltage';
import { useAuth } from '../../protected/ProviderAuth';
import './styles.scss';

type MenuItem = Required<any>['items'][number];
interface MenuComponentProps {
    collapsed?: boolean;
}

const MenuComponent: React.FC<MenuComponentProps> = ({ collapsed }) => {
    const [current, setCurrent] = useState('1');
    const navigate = useNavigate();
    const { hasPermissionList } = useAuth();

    const items: MenuItem[] = [
        {
            key: 'dashboard',
            label: 'Dashboard',
            icon: <HomeOutlined />,
            visible: hasPermissionList([
                PermissionReports.DASHBOARD,
                PermissionReports.SALES_GENERAL,
                PermissionOrder.GET
            ]),
        },
        {
            key: 'administradores-main',
            label: 'Administradores',
            icon: <TeamOutlined />,
            visible: hasPermissionList([
                PermissionAdministrator.GET,
                PermissionAdministrator.GET_UUID,
                PermissionAdministrator.POST,
                PermissionAdministrator.PUT,
                PermissionAdministrator.DELETE,
                PermissionAuthorityGroups.GET,
                PermissionAuthorityGroups.GET_UUID,
                PermissionAuthorityGroups.POST,
                PermissionAuthorityGroups.PUT,
                PermissionAuthorityGroups.DELETE,
            ]),
            children: [
                {
                    key: 'administradores', label: 'Administradores', visible: hasPermissionList([
                        PermissionAdministrator.GET,
                        PermissionAdministrator.GET_UUID,
                        PermissionAdministrator.POST,
                        PermissionAdministrator.PUT,
                        PermissionAdministrator.DELETE,
                    ]),
                },
                {
                    key: 'perfis', label: 'Perfis', visible: hasPermissionList([
                        PermissionAuthorityGroups.GET,
                        PermissionAuthorityGroups.GET_UUID,
                        PermissionAuthorityGroups.POST,
                        PermissionAuthorityGroups.PUT,
                        PermissionAuthorityGroups.DELETE,
                    ]),
                },
                // {
                //     key: 'cargos', label: 'Cargos', visible: true
                // },
            ],
        },
        {
            key: 'client',
            label: 'Cliente',
            icon: <UserOutlined />,
            visible: hasPermissionList([
                PermissionCustomer.GET,
                PermissionCustomer.GET_UUID,
                PermissionCustomer.POST,
                PermissionCustomer.PUT,
                PermissionCustomer.DELETE,
                PermissionChannel.GET,
                PermissionChannel.GET_UUID,
                PermissionChannel.POST,
                PermissionChannel.PUT,
                PermissionChannel.DELETE,
                PermissionSubChannel.GET,
                PermissionSubChannel.GET_UUID,
                PermissionSubChannel.POST,
                PermissionSubChannel.PUT,
                PermissionSubChannel.DELETE,
            ]),
            children: [
                {
                    key: 'cliente', label: 'Clientes', visible: hasPermissionList([
                        PermissionCustomer.GET,
                        PermissionCustomer.GET_UUID,
                        PermissionCustomer.POST,
                        PermissionCustomer.PUT,
                        PermissionCustomer.DELETE,
                    ]),
                },
                {
                    key: 'canal', label: 'Canais', visible: hasPermissionList([
                        PermissionChannel.GET,
                        PermissionChannel.GET_UUID,
                        PermissionChannel.POST,
                        PermissionChannel.PUT,
                        PermissionChannel.DELETE,
                    ]),
                },
                {
                    key: 'subcanal', label: 'SubCanal', visible: hasPermissionList([
                        PermissionSubChannel.GET,
                        PermissionSubChannel.GET_UUID,
                        PermissionSubChannel.POST,
                        PermissionSubChannel.PUT,
                        PermissionSubChannel.DELETE,
                    ]),
                },
                // {
                //     key: 'edicao-clientes-em-massa', label: 'Inclusão em Massa', visible: true,
                // },
            ],
        },
        {
            key: 'pedidos',
            label: 'Pedidos',
            icon: <FileDoneOutlined />,
            visible: hasPermissionList([
                PermissionOrder.GET,
                PermissionOrder.GET_UUID,
                PermissionOrder.PUT,
            ]),
        },
        // {
        //     key: 'filiais',
        //     label: 'Filiais',
        //     icon: <ShopOutlined />,
        //     visible: hasPermissionList([
        //         PermissionBanners.GET,
        //         PermissionBanners.GET_UUID,
        //         PermissionBanners.POST,
        //         PermissionBanners.PUT,
        //         PermissionBanners.DELETE,
        //     ]),
        // },
        {
            key: 'produtos-main',
            label: 'Produtos',
            icon: <InboxOutlined />,
            visible: hasPermissionList([
                PermissionProducts.GET,
                PermissionProducts.GET_UUID,
                PermissionProducts.POST,
                PermissionProducts.PUT,
                PermissionProducts.DELETE,
                PermissionEvaluations.GET,
                PermissionEvaluations.GET_UUID,
                PermissionProperties.GET,
                PermissionProperties.GET_UUID,
                PermissionProperties.POST,
                PermissionProperties.PUT,
                PermissionProperties.DELETE,
            ]),
            children: [
                {
                    key: 'produtos', label: 'Produtos', visible: hasPermissionList([
                        PermissionProducts.GET,
                        PermissionProducts.GET_UUID,
                        PermissionProducts.POST,
                        PermissionProducts.PUT,
                        PermissionProducts.DELETE,
                    ]),
                },
                {
                    key: 'produtos-variacao', label: 'Produtos Variação', visible: hasPermissionList([
                        PermissionProducts.GET,
                        PermissionProducts.GET_UUID,
                        PermissionProducts.POST,
                        PermissionProducts.PUT,
                        PermissionProducts.DELETE,
                    ]),
                },
                {
                    key: 'avaliacoes', label: 'Avaliações', visible: hasPermissionList([
                        PermissionEvaluations.GET,
                        PermissionEvaluations.GET_UUID,
                    ]),
                },
                {
                    key: 'especificacao', label: 'Especificação', visible: hasPermissionList([
                        PermissionProperties.GET,
                        PermissionProperties.GET_UUID,
                        PermissionProperties.POST,
                        PermissionProperties.PUT,
                        PermissionProperties.DELETE,
                    ]),
                },
                {
                    key: 'produtos/categorias', label: 'Produtos por Categoria', visible: hasPermissionList([
                        PermissionProducts.GET,
                        PermissionProducts.GET_UUID,
                        PermissionProducts.POST,
                        PermissionProducts.PUT,
                        PermissionProducts.DELETE,
                    ]),
                },
                // {
                //     key: 'inclusao-produtos-em-massa', label: 'Inclusão em Massa', visible: true,
                // },
                // {
                //     key: 'edicao-produtos-em-massa', label: 'Edição em Massa', visible: true,
                // },
            ],
        },
        {
            key: 'atributos',
            label: 'Atributos',
            icon: <SisternodeOutlined />,
            visible: hasPermissionList([
                PermissionColor.GET,
                PermissionColor.GET_UUID,
                PermissionColor.POST,
                PermissionColor.PUT,
                PermissionColor.DELETE,
                PermissionVoltage.GET,
                PermissionVoltage.GET_UUID,
                PermissionVoltage.POST,
                PermissionVoltage.PUT,
                PermissionVoltage.DELETE,
                PermissionMaterials.GET,
                PermissionMaterials.GET_UUID,
                PermissionMaterials.POST,
                PermissionMaterials.PUT,
                PermissionMaterials.DELETE,
                PermissionSize.GET,
                PermissionSize.GET_UUID,
                PermissionSize.POST,
                PermissionSize.PUT,
                PermissionSize.DELETE,
            ]),
            children: [
                {
                    key: 'cores', label: 'Cores', visible: hasPermissionList([
                        PermissionColor.GET,
                        PermissionColor.GET_UUID,
                        PermissionColor.POST,
                        PermissionColor.PUT,
                        PermissionColor.DELETE,
                    ]),
                },
                {
                    key: 'voltagem', label: 'Voltagem', visible: hasPermissionList([
                        PermissionVoltage.GET,
                        PermissionVoltage.GET_UUID,
                        PermissionVoltage.POST,
                        PermissionVoltage.PUT,
                        PermissionVoltage.DELETE,
                    ]),
                },
                {
                    key: 'material', label: 'Materias', visible: hasPermissionList([
                        PermissionMaterials.GET,
                        PermissionMaterials.GET_UUID,
                        PermissionMaterials.POST,
                        PermissionMaterials.PUT,
                        PermissionMaterials.DELETE,
                    ]),
                },
                {
                    key: 'tamanhos', label: 'Tamanho', visible: hasPermissionList([
                        PermissionSize.GET,
                        PermissionSize.GET_UUID,
                        PermissionSize.POST,
                        PermissionSize.PUT,
                        PermissionSize.DELETE,
                    ]),
                },
            ],
        },
        {
            key: 'categorias',
            label: 'Categorias',
            icon: <GoldOutlined />,
            visible: hasPermissionList([
                PermissionCategories.GET,
                PermissionCategories.GET_UUID,
                PermissionCategories.POST,
                PermissionCategories.PUT,
                PermissionCategories.DELETE,
            ]),
        },
        {
            key: 'parcelas',
            label: 'Condição de Pagamento',
            icon: <CreditCardOutlined />,
            visible: hasPermissionList([
                PermissionPayment.GET,
                PermissionPayment.GET_UUID,
                PermissionPayment.POST,
                PermissionPayment.PUT,
                PermissionPayment.DELETE,
            ]),
        },
        {
            key: 'config',
            label: 'Configuração Loja',
            icon: <SettingOutlined />,
            visible: hasPermissionList([
                PermissionConfig.GET,
                PermissionConfig.PUT,
                PermissionInstitucional.GET,
                PermissionInstitucional.GET_UUID,
                PermissionInstitucional.POST,
                PermissionInstitucional.PUT,
                PermissionInstitucional.DELETE,
                PermissionStamps.GET,
                PermissionStamps.GET_UUID,
                PermissionStamps.POST,
                PermissionStamps.PUT,
                PermissionStamps.DELETE,
            ]),
            children: [
                {
                    key: 'configuracao', label: 'Configurações', visible: hasPermissionList([
                        PermissionConfig.GET,
                        PermissionConfig.PUT,
                    ]),
                },
                {
                    key: 'institucional', label: 'Institucional', visible: hasPermissionList([
                        PermissionInstitucional.GET,
                        PermissionInstitucional.GET_UUID,
                        PermissionInstitucional.POST,
                        PermissionInstitucional.PUT,
                        PermissionInstitucional.DELETE,
                    ]),
                },
                {
                    key: 'selos', label: 'Selos', visible: hasPermissionList([
                        PermissionStamps.GET,
                        PermissionStamps.GET_UUID,
                        PermissionStamps.POST,
                        PermissionStamps.PUT,
                        PermissionStamps.DELETE,
                    ]),
                },
            ],
        },
        {
            key: 'shipping',
            label: 'Frete',
            icon: <RocketOutlined />,
            visible: hasPermissionList([
                PermissionShipping.GET,
                PermissionShipping.GET_UUID,
                PermissionShipping.POST,
                PermissionShipping.PUT,
                PermissionShipping.DELETE,
            ]),
            children: [
                {
                    key: 'fretes', label: 'Fretes', visible: hasPermissionList([
                        PermissionShipping.GET,
                        PermissionShipping.GET_UUID,
                        PermissionShipping.POST,
                        PermissionShipping.PUT,
                        PermissionShipping.DELETE,
                    ]),
                },
                {
                    key: 'upload-frete', label: 'Upload Fretes', visible: hasPermissionList([
                        PermissionShipping.GET,
                        PermissionShipping.GET_UUID,
                        PermissionShipping.POST,
                        PermissionShipping.PUT,
                        PermissionShipping.DELETE,
                    ]),
                },
            ],
        },
        {
            key: 'home-main',
            label: 'Página Inicial',
            icon: <AppstoreOutlined />,
            visible: hasPermissionList([
                PermissionHome.GET,
                PermissionHome.GET_UUID,
                PermissionHome.POST,
                PermissionHome.PUT,
                PermissionHome.DELETE,
                PermissionBanners.GET,
                PermissionBanners.GET_UUID,
                PermissionBanners.POST,
                PermissionBanners.PUT,
                PermissionBanners.DELETE,
            ]),
            children: [
                {
                    key: 'banners',
                    label: 'Banners',
                    visible: hasPermissionList([
                        PermissionBanners.GET,
                        PermissionBanners.GET_UUID,
                        PermissionBanners.POST,
                        PermissionBanners.PUT,
                        PermissionBanners.DELETE,
                    ]),
                },
                {
                    key: 'componentes', label: 'Componentes', visible: hasPermissionList([
                        PermissionHome.GET,
                        PermissionHome.GET_UUID,
                        PermissionHome.POST,
                        PermissionHome.PUT,
                        PermissionHome.DELETE,
                    ]),
                },
                {
                    key: 'home', label: 'Home', visible: hasPermissionList([
                        PermissionHome.GET,
                        PermissionHome.GET_UUID,
                        PermissionHome.POST,
                        PermissionHome.PUT,
                        PermissionHome.DELETE,
                    ]),
                },
            ],
        },
        {
            key: 'promotion',
            label: 'Promoção',
            icon: <TagsOutlined />,
            visible: hasPermissionList([
                PermissionCampaigns.GET,
                PermissionCampaigns.GET_UUID,
                PermissionCampaigns.POST,
                PermissionCampaigns.PUT,
                PermissionCampaigns.DELETE,
                PermissionCupons.GET,
                PermissionCupons.GET_UUID,
                PermissionCupons.POST,
                PermissionCupons.PUT,
                PermissionCupons.DELETE,
            ]),
            children: [
                {
                    key: 'campanhas', label: 'Campanhas', visible: hasPermissionList([
                        PermissionCampaigns.GET,
                        PermissionCampaigns.GET_UUID,
                        PermissionCampaigns.POST,
                        PermissionCampaigns.PUT,
                        PermissionCampaigns.DELETE,
                    ]),
                },
                {
                    key: 'cupons', label: 'Cupons', visible: hasPermissionList([
                        PermissionCupons.GET,
                        PermissionCupons.GET_UUID,
                        PermissionCupons.POST,
                        PermissionCupons.PUT,
                        PermissionCupons.DELETE,
                    ]),
                },
            ],
        },
        {
            key: 'reports',
            label: 'Relatórios',
            icon: <SettingOutlined />,
            visible: hasPermissionList([
                PermissionReports.ABANDONED_CARTS_GENERAL,
                PermissionReports.BEST_SELLING_CATEGORIES,
                PermissionReports.BEST_SELLING_PRODUCTS,
                PermissionReports.CAMPAINGS,
                PermissionReports.LOGINS,
                PermissionReports.RATINGS_BY_PRODUCTS,
                PermissionReports.SALES_GENERAL,
                PermissionReports.VOUCHERS,
            ]),
            children: [
                { key: 'visao-geral', label: 'Visão Geral', visible: hasPermissionList([PermissionReports.SALES_GENERAL]) },
                { key: 'relatorio-carrinho', label: 'Carrinho Abandonado', visible: hasPermissionList([PermissionReports.ABANDONED_CARTS_GENERAL]) },
                { key: 'relatorio-produtos-mais-comprados', label: 'Produtos mais Comprados', visible: hasPermissionList([PermissionReports.BEST_SELLING_PRODUCTS]) },
                { key: 'vendas-por-categoria', label: 'Vendas por Categoria', visible: hasPermissionList([PermissionReports.BEST_SELLING_CATEGORIES]) },
                { key: 'relatorio-cupons', label: 'Cupons', visible: hasPermissionList([PermissionReports.VOUCHERS]) },
                { key: 'relatorio-campanhas', label: 'Campanhas', visible: hasPermissionList([PermissionReports.CAMPAINGS]) },
                { key: 'relatorio-avaliacoes', label: 'Avaliações por Produto', visible: hasPermissionList([PermissionReports.RATINGS_BY_PRODUCTS]) },
                { key: 'log-de-acesso', label: 'Log de Acesso', visible: hasPermissionList([PermissionReports.LOGINS]) },
            ],
        },
        {
            key: 'template',
            label: 'Template E-mail',
            icon: <MailOutlined />,
            visible: hasPermissionList([
                PermissionTemplateEmail.GET,
                PermissionTemplateEmail.GET_UUID,
                PermissionTemplateEmail.POST,
                PermissionTemplateEmail.PUT,
                PermissionTemplateEmail.DELETE,
            ]),
            // children: [
            //     { key: 'template-email', label: 'Template E-mail', visible: true },
            //     { key: 'Newsletter', label: 'Newsletter', visible: true },
            // ],
        },
        {
            key: 'log-sistema',
            label: 'Logs do Sistema',
            icon: <SettingOutlined />,
            visible: true,
        },
        // {
        //     key: 'merchant',
        //     label: 'Merchant',
        //     icon: <GoldOutlined />,
        //     visible: true,
        // },
    ];


    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        navigate(`/${e.key}`);
    };


    const filteredItems = items
        .filter(item => item.visible) // Filtra os itens principais com base na visibilidade
        .map(item => ({
            ...item,
            children: item.children?.filter((child: any) => child.visible), // Filtra os sub-itens (children)
        }));

    const removeVisibleKey = (items: MenuItem[]) => {
        return items.map(item => {
            // Remove a chave 'visible' do item principal
            const { visible, ...restItem } = item;

            // Se houver children, remove 'visible' de cada child também
            const newItem = {
                ...restItem,
                children: item.children?.map((child: any) => {
                    const { visible, ...restChild } = child; // Remove 'visible' do sub-item
                    return restChild;
                }),
            };

            return newItem;
        });
    };

    const finalItems = removeVisibleKey(filteredItems);

    return (
        <div className="flex flex-col bg-white dark:bg-charcoal custom-menu-color">
            {!collapsed ?
                <div className="menu-logo flex flex-col items-center pt-6">
                    <img src="/assets/3c-logo.png" alt="Logo" style={{ width: '150px' }} />
                </div> :
                <div className="menu-logo flex flex-col items-center pt-6">
                    <img src="/assets/3c-logo-mini.png" alt="Logo" style={{ width: '40px', maxWidth: '40px' }} />
                </div>
            }

            <Menu
                theme={'light'}
                onClick={onClick}
                style={collapsed ? { width: 80 } : { width: 256 }}
                defaultOpenKeys={['sub1']}
                selectedKeys={[current]}
                inlineCollapsed={collapsed}
                mode="inline"
                items={finalItems}// Filtra o item principal
            />
        </div>
    );
};

export default MenuComponent;