import { message } from 'antd';
import Cookies from 'js-cookie';
import { makeAutoObservable, runInAction } from 'mobx';
import { COOKIE_NAMES, COOKIE_OPTIONS } from '../config/cookieConstants';
import AdministratorService from '../services/AdministratorService';
import AuthService from '../services/AuthService';
import { Merchant } from '../types/Merchant';
import { encryptData } from '../utils/encryptDataFunctions';


// Salvar o token no cookie
export const saveAuthToken = (token: string, options: any) => {
    Cookies.set(COOKIE_NAMES.TOKEN, token, {
        ...options,
        sameSite: 'Strict',
        secure: true,
    });
};

export const saveEmail = (email: string) => {
    Cookies.set(COOKIE_NAMES.EMAIL, email, {
        ...COOKIE_OPTIONS.TOKEN,
        sameSite: 'Strict',
        secure: true,
    });
};

export const saveRemenber = (email: string, options: any) => {
    Cookies.set(COOKIE_NAMES.REMEMBER, email, {
        ...COOKIE_OPTIONS.TOKEN,
        sameSite: 'Strict',
        secure: true,
    });
};

// Pegar o token do cookie
export const getAuthToken = () => {
    return Cookies.get(COOKIE_NAMES.TOKEN);
};

export const getEmailCookie = () => {
    return Cookies.get(COOKIE_NAMES.EMAIL);
};

export const getRemember = () => {
    return Cookies.get(COOKIE_NAMES.REMEMBER);
};

// Remover o token do cookie
export const removeAuthToken = () => {
    Cookies.remove(COOKIE_NAMES.TOKEN);
    Cookies.remove(COOKIE_NAMES.EMAIL);
};

class AuthStore {
    isAuthenticated: boolean = false;
    loading: boolean = false;
    loginProps: any;
    resendEmailData: any;
    account: any;
    userSave: string = '';
    merchant: Merchant | undefined;

    constructor() {
        makeAutoObservable(this);

        const token = getAuthToken();
        if (token) {
            this.isAuthenticated = true;
        }
    }


    async verifyTwoFactorAuth(email: string, password: string) {
        this.loading = true;
        const data = {
            email: encryptData(email),
            password: encryptData(password),
            username: encryptData(email),
            "@class": "br.com.stoom.kernel.model.persistence.User"
        }

        const newData = {
            email: email,
            password: password,
            username: email,
            "@class": "br.com.stoom.kernel.model.persistence.User"
        }

        this.resendEmailData = newData
        const response = await AuthService.authentication2fa(data);

        if (response.error) {
            message.error('Erro ao enviar o e-mail');
        } else {
            message.success('E-mail enviado com sucesso');
            saveEmail(email);
            this.loginProps = data;
        }

        this.loading = false;
        return response;
    }

    async login(code: string) {
        const data = {
            password: this.loginProps.password,
            username: this.loginProps.email,
        }

        const response = await AuthService.login(code, data);

        if (!response.error) {
            message.success('Login realizado com sucesso');
            const remember = getRemember();

            if (remember) {
                saveAuthToken(response.headers.authorization, COOKIE_OPTIONS.TOKEN);
            } else {
                saveAuthToken(response.headers.authorization, COOKIE_OPTIONS.NOT_REMEMBER);
            }
            this.isAuthenticated = true;
        } else {
            message.error('Autenticação falhou');
        }

        return response;
    }

    logout() {
        removeAuthToken();
        this.isAuthenticated = false;
    }

    setAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated = isAuthenticated;
    }

    checkAuthentication() {
        const token = getAuthToken();
        if (token) {
            this.isAuthenticated = true;
        } else {
            this.isAuthenticated = false;
        }
    }

    async redefinePass(email: string) {
        this.loading = true;

        const response = await AuthService.redefinePass(email);

        runInAction(() => {
            if (response?.error) {
                message.error('Erro ao enviar o e-mail');
            } else {
                message.success('E-mail enviado com sucesso');
            }
            this.loading = false;
        });

        return response;
    }

    async validateToken(token: string) {
        this.loading = true;

        const response = await AuthService.validateToken(token);

        runInAction(() => {
            if (response?.error) {
                message.error('Erro ao validar token');
            } else {
                message.success('Token validado com sucesso');
            }
            this.loading = false;
        });

        return response;
    }

    async changePassword(customerUUID: string, token: string, newPassword: string) {
        this.loading = true;

        const response = await AuthService.changePassword(
            customerUUID,
            token,
            newPassword
        );

        runInAction(() => {
            if (response?.error) {
                message.error('Erro ao alterar senha');
            } else {
                message.success('Senha alterada com sucesso com sucesso');
            }
            this.loading = false;
        });

        return response;
    }

    async getAdministradorAccount(email: string | undefined) {
        const response = await AdministratorService.getAccount(email);
        if (response.error) {
            message.error(`Erro ao buscar Administrador`);
            removeAuthToken();
        }

        this.merchant = response.data.merchant;
        this.account = response.data;
        return response.data;
    }
}

const authStore = new AuthStore();
export default authStore;