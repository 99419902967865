import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, message, Space, Table, Tag } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionSubChannel } from '../../../permissions/PermissionSubChannel';
import channelStore from '../../../stores/channelStore';
import subChannel from '../../../stores/subChannel';
import { SubChannelModel } from '../../../types/SubChannel';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters, getUniquePropertyFilters } from '../../../utils/functions';
import { getStatusColor } from '../../../utils/statusColorMapper';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    active: boolean | React.Key;
    channel: any;
    name: string;
    code: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    code?: string;
    name?: string;
    channel?: any;
    created?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

const ListChannel: React.FC = observer(() => {
    const { subChannelList, loading, page, totalElements, defaultparams, sort, size } = subChannel;
    const { selectChannelList } = channelStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const navigate = useNavigate();
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const listRouter = () => { navigate(`/subcanal/create`); }
    const { hasPermission, isColumnVisible } = useAuth();

    const initialValues = {
        channel_name: typeof queryParams.channel_name === 'string'
            ? { value: queryParams.channel_name, label: queryParams.channel_name }
            : { value: '', label: '' },
        name: Array.isArray(queryParams.name) ? queryParams.name[0] || '' : queryParams.name || '',
    };

    // Search Channel Params
    const defaultparamsChannel = {
        size: 99999,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    // BreadCrumb
    const breadCrumb = [
        {
            title: <Link to="/dashboard">Dashboard</Link>,
        },
        {
            title: <p>Listagem de SubCanais</p>,
        },
    ]


    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        channel_name: 'channel_name',
        name: 'name',
        created: 'created'
    };

    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings, ':');
        channelStore.getList(defaultparamsChannel);

        const fetchData = async () => {
            if (hasPermission(PermissionSubChannel.GET)) {
                await subChannel.getList(params);
            }
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            filters: getUniqueFilters(subChannelList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
        },
        {
            title: 'Canal',
            dataIndex: 'channel',
            key: 'channel.name',
            //@ts-ignore
            filters: getUniquePropertyFilters<SubChannelModel, 'channel', 'name'>(subChannelList, 'channel', 'name'),
            onFilter: (value, record) => record.channel.name.indexOf(value as string) === 0, // Acesse o nome do canal
            sorter: true,
            defaultSortOrder: getSortOrder('channel.name', queryParams.sort),
            render: (item) => {
                return (
                    <>{item && <p>{item.name}</p>}</>
                );
            },
        },
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            filters: getUniqueFilters(subChannelList, 'code'),
            onFilter: (value, record) => record.code.indexOf(value as string) === 0,
            sorter: true,
            defaultSortOrder: getSortOrder('code', queryParams.sort),
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            defaultSortOrder: getSortOrder('created', queryParams.sort),
            sorter: true,
            render: (text) => {
                return (
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Ativo',
            dataIndex: 'active',
            key: 'active',
            sorter: true,
            filters: [
                { text: 'Ativo', value: true },
                { text: 'Inativo', value: false },
            ],
            onFilter: (value, record) => record.active === value,
            render: (active) => {
                let text = active ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionSubChannel.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => navigate(`/subcanal/create/${record.uuid}`)}>
                    <div><EditOutlined /></div>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionSubChannel.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    // Faz o filtro da tabela
    const handleSubmit = async (values: { channel_name: { value: string; label: string }; name: string }) => {
        const newParams: any = {
            channel_name: values.channel_name.label || undefined,
            name: values.name || undefined,
        };

        const search = [
            values.channel_name.label ? `channel_name:"${values.channel_name.label}"` : null,
            values.name ? `name;"${values.name}"` : null,
        ]
            .filter(Boolean)
            .join(' ');

        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sort || 'created,desc',
            search,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await subChannel.getList(params);
    };

    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm();
        setQueryParams({});
        await subChannel.getList(defaultparams);

        const newInitialValues = {
            name: '',
        };

        //@ts-ignore
        resetForm(newInitialValues);
    };


    // Interação da Tabela como sorte
    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await subChannel.getList(params);
    };

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = async () => {
        const response = await subChannel.deleteSubChannel(itemSelected.uuid);

        if (!response?.error) {
            subChannel.getList(defaultparams);
            setIsModalOpen(false);
        }
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const filters = buildParamsFromQuery(queryParams, mappings, ':');

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort || 'created,desc',
            search: filters.search || queryParams.search || '',
        };

        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
            search: params.search,
        });

        return params;
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };

    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => subChannel.deleteSubChannelAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    setSelectionCheckbox([]);
                    message.error('Erro ao deletar um ou mais Subcanais.');
                } else {
                    setSelectionCheckbox([]);
                    message.success('Todos os SubCanais foram deletados com sucesso!');
                    await subChannel.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar os Subcanais.');
        }
    };

    const handleExport = async () => {
        const params = buildParamsFromQuery(queryParams, mappings, ':');
        const exportParams = {
            ...params,
            size: 999999,
        }

        const dataExport = await subChannel.getList(exportParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: SubChannelModel) => ({
                name: item.name,
                channel: item.channel.name,
                code: item.code,
                created: formatDateString(item.created ?? ""),
                active: item?.active ? "Sim" : "Não",
            }));

            exportToExcel(listExport, ["Nome", "Canal", "Código", "Data de Criação", "Ativo"], "Tabela de SubCanais.xlsx");
        }
    };

    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionSubChannel.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <Permission permissionKeys={[PermissionSubChannel.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Listagem de SubCanais" description='Tela de listagem de SubCanais'>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ resetForm }) => (  // Passa o resetForm do render prop de Formik
                            <Form>
                                <div className="flex items-end w-full gap-4">
                                    <div className="w-full">
                                        <InputComponet label="Nome" name="name" id="name" />
                                    </div>
                                    <div className="w-full">
                                        <SelectComponent label="Canal" name="channel_name" options={selectChannelList} />
                                    </div>
                                    <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                                        <Button className="w-full" onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                                        <Button className="w-full" htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form >
                        )}
                    </Formik >
                </ContentBox >

                <ContentBox className="mb-4 p-6" description='Listagem de SubCanais' actions={
                    <>
                        <Permission permissionKeys={[PermissionSubChannel.DELETE]}><ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton></Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={subChannelList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        loading={loading}
                        rowSelection={rowSelection}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            {isModalOpen && <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o SubCanal <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />}

            {isModalDeleteOpen && <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />}
        </>
    );
});

export default ListChannel;