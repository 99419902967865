import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionCategories } from '../../../../permissions/PermissionCategories';
import categoryStore from '../../../../stores/categoryStore';
import { YesOrNot } from '../../../../utils/selects';
import ActionButton from '../../../atoms/ButtonComponent';
import InputComponent from '../../../atoms/InputComponet';
import SelectComponent from '../../../atoms/SelectComponent';
import SwitchComponent from '../../../atoms/SwitchComponent';
import TextAreaComponent from '../../../atoms/TextAreaComponent';
import ContentBox from '../../../molecules/ContentBox';
import Permission from '../../../molecules/Permission';
import ModalComponent from '../../../organisms/ModalComponent';

const CategoryInformation: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();

    const { categoryProps } = categoryStore;
    const navigate = useNavigate();

    const listRouter = () => {
        navigate(`/categorias`);
    }


    return (
        <div>
            <ContentBox className="mb-4 p-6 rounded-none" title="Cadastro de Categoria" description='Tela para cadastro de Categoria' actions={
                <>
                    <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
                </>
            }>
                <div className="w-full flex flex-col gap-2">
                    <div className="flex gap-4">
                        <InputComponent label="Nome" name="categoryName" id="categoryName" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Ordenação Menu Superior" name="ordering" id="ordering" />
                        <InputComponent disabled label="Identificador" name="externalId" id="externalId" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Meta title" name="metaTitle" id="metaTitle" />
                        <InputComponent label="Meta description" name="metaDescription" id="metaDescription" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Meta Canônica" name="metaCanonical" id="metaCanonical" />
                        <SelectComponent options={YesOrNot} label="Exibir no menu Superior?" name="menuDisplay" />
                    </div>

                    <div className="flex gap-4">
                        <SelectComponent options={YesOrNot} label="Exibir categoria na Home?" name="homeDisplay" />
                    </div>

                    <div className="flex gap-4">
                        <TextAreaComponent label="Descrição curta" name="shortDescription" id="shortDescription" />
                    </div>
                </div>
            </ContentBox>


        </div>
    );
});

export default CategoryInformation;