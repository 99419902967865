import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import AdministratorService from '../services/AdministratorService';
import AuthoritiesService from '../services/AuthoritiesService';
import { PaginationParams } from '../types';
import { transformToValueLabel } from '../utils/functions';
import { AdministratorModel } from '../types/Administrator';
import { getDifferences } from '../utils/functionAuthorities';


class AdministratorStore {
    loading: boolean = false;
    administratorList: AdministratorModel[] = [];
    administrator: AdministratorModel | undefined;
    listGroup: any = []
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.administrator = undefined;
        this.administratorList = [];
        this.listGroup = [];
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await AdministratorService.getList(params);

        if (!response.error) {
            this.administratorList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
            this.sort = params.sort;
        }
        this.loading = false;

        return response;
    }

    async getAdministratorByUuid(uuid: string) {
        this.loading = true;
        const response = await AdministratorService.getAdministratorByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar Administrador');
                return;
            }

            this.listGroup = transformToValueLabel(response.data.user.authorityGroups, 'uuid', 'name');
            this.administrator = response.data;
            this.loading = false;
        });

        return response.data;
    }

    async addIndividualAuthorities(uuid: string | undefined, individualPermission: any) {
        individualPermission.map(async (item: any) => {
            await AuthoritiesService.createIndividualAuthoritie(item.uuid, uuid);
        })
    }

    async deleteIndividualAuthorities(uuid: string | undefined, individualPermission: any) {
        individualPermission.map(async (item: any) => {
            await AuthoritiesService.deleteIndividualAuthoritie(item.uuid, uuid);
        })
    }

    async createAdministrator(data: any, listGroups: any, individualPermission: any) {
        this.loading = true;
        const response = await AdministratorService.createAdministrator(data);

        if (response.error) {
            message.error('Erro ao criar Administrador');
        } else {
            message.success('Administrador cadastrado com sucesso!');

            if (listGroups?.length > 0) {
                listGroups.map(async (item: any) => {
                    await AuthoritiesService.createAuthoritiesGroup(item.value, response.data.user.uuid);
                })
            }

            await this.addIndividualAuthorities(response.data.user.uuid, individualPermission);
        }

        this.loading = false;
        return response;
    }

    async editAdministrator(data: any, uuid: string | undefined, groups: any, individualPermission: any) {
        this.loading = true;
        const response = await AdministratorService.editAdministrator(data, uuid);

        const setLista1 = new Set(this.listGroup?.map((item: any) => item.value));
        const setLista2 = new Set(groups?.map((item: any) => item.value));
        const newGroup = groups?.filter((item: any) => !setLista1?.has(item.value));
        const removeGroup = this.listGroup?.filter((item: any) => !setLista2?.has(item.value));

        if (response.error) {
            message.error('Erro ao editar Administrador');
            this.administrator = data;
        } else {
            message.success('Administrador editado com sucesso!');

            // Grupo de Usuario
            if (newGroup?.length > 0) {
                newGroup?.map(async (item: any) => {
                    await AuthoritiesService.createAuthoritiesGroup(item.value, response.data.user.uuid)
                })
            }

            if (removeGroup?.length > 0) {
                removeGroup.map(async (item: any) => {
                    await AuthoritiesService.deleteAuthoritiesGroup(item.value, response.data.user.uuid)
                })
            }

            if (this.administrator) {
                const { toAdd, toRemove } = getDifferences(this.administrator.user.authorities, individualPermission);

                if (toRemove.length > 0) {
                    this.deleteIndividualAuthorities(response.data.user.uuid, toRemove)
                }

                if (toAdd.length > 0) {
                    this.addIndividualAuthorities(response.data.user.uuid, individualPermission);
                }
            }
        }

        this.loading = false;
        return [];
    }

    async deleteAdministratorAll(uuid: string) {
        this.loading = true;
        const response = await AdministratorService.deleteAdministrator(uuid);
        this.administrator = response.data;
        this.loading = false;
        return response;
    }


    async deleteAdministrator(uuid: string) {
        this.loading = true;
        const response = await AdministratorService.deleteAdministrator(uuid);
        if (response.error) {
            message.error(`Erro ao deletar Administrador`);
        } else {
            message.success('Administrador deletado com sucesso!');
        }
        this.administrator = response.data;
        this.loading = false;
        return response;
    }
}

const administratorStore = new AdministratorStore();
export default administratorStore;