import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import authStore, { getEmailCookie } from '../../stores/authStore';
import { mergeAuthorities } from '../../utils/functionAuthorities';
import { useNavigate } from 'react-router-dom';

interface Authority {
    uuid: string;
    name: string;
    pattern: string;
    groupedBy: string;
    action: string;
    description: string;
    permitAll: boolean;
    show: boolean;
    key: string;
    active: boolean;
    deleted: boolean;
}

interface AuthContextType {
    authorities: Authority[] | null;
    hasPermission: (key: string) => boolean;
    isColumnVisible: (key: string) => boolean;
    hasPermissionList: (key: string[]) => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [authorities, setAuthorities] = useState<Authority[] | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAuthorities = async () => {
            const email = getEmailCookie();
            const response = await authStore.getAdministradorAccount(email);

            if (response.error) {
                navigate('/login');
            }

            const authoritiesList = mergeAuthorities(response.user);
            setAuthorities(authoritiesList);
        };
        fetchAuthorities();
    }, []);

    const hasPermission = (key: string) => authorities?.some(auth => auth.key === key && auth.active && !auth.deleted) ?? false;
    const isColumnVisible = (permissionKey: string): boolean => hasPermission(permissionKey);

    const hasPermissionList = (keys: string | string[]) => {
        const keysArray = Array.isArray(keys) ? keys : [keys];
        return keysArray.some(key =>
            authorities?.some(auth => auth.key === key && auth.active && !auth.deleted)
        );
    };

    // Caso as permissões ainda estejam sendo carregadas, retornamos `null`
    if (!authorities) return null;

    return (
        <AuthContext.Provider value={{ authorities, hasPermission, isColumnVisible, hasPermissionList }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};