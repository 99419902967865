import { message } from 'antd';
import { makeAutoObservable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import CampaignService from '../services/CampaignService';
import { PaginationParams } from '../types';
import { removeSpecialCharacters } from '../utils/functions';
import ProductService from '../services/ProductService';


class CampaingStore {
    loading: boolean = false;
    campaignList: any = [];
    campaignProps: any;
    zipCodeRestriction: any;
    categoriesOrProdutcts: any;
    zipCodeRestrictionList: any = [];
    dateRestrictionList: any = [];
    categoryList: any = [];
    customerRestrictionItem: any = [];
    channelOrSubChannel: any;
    productList: any = [];
    customerList: any = [];
    reportsCampaign?: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.campaignProps = undefined;
        this.zipCodeRestrictionList = [];
        this.dateRestrictionList = [];
        this.zipCodeRestriction = undefined;
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await CampaignService.getList(params);

        if (!response.error) {
            this.campaignList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
            this.sort = params.sort;
        }
        this.loading = false;

        return response;
    }


    async getCampaingByUuid(uuid: string) {
        this.loading = true;
        const response = await CampaignService.getCampaingByUuid(uuid);

        if (response.error) {
            message.error(`Erro ao buscar Campanha`);
            return;
        }

        const zipCodeRestriction = response?.data?.restrictions.find((restriction: any) =>
            restriction["@class"] === "br.com.stoom.kernel.model.persistence.restriction.ZipCodeRestriction"
        );

        const dateRestriction = response?.data?.restrictions.find((restriction: any) =>
            restriction["@class"] === "br.com.stoom.kernel.model.persistence.restriction.DateRestriction"
        );

        const categoryRestriction = response?.data?.restrictions.find((restriction: any) =>
            restriction["@class"] === "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction"
        );

        const productRestriction = response?.data?.restrictions.find((restriction: any) =>
            restriction["@class"] === "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction"
        );

        const customerRestricion = response?.data?.restrictions.find((restriction: any) =>
            restriction["@class"] === "br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction"
        );

        const channelOrSubChannel = response?.data?.restrictions.find((restriction: any) =>
            restriction["@class"] === "br.com.stoom.tresCoracoes.model.restrictions.TresCoracoesChannelRestriction"
        );

        const uuids = categoryRestriction?.categories?.map((category: any) => category.uuid);

        this.channelOrSubChannel = channelOrSubChannel;
        this.customerRestrictionItem = customerRestricion;
        this.customerList = customerRestricion?.documents
        this.categoryList = uuids;
        this.productList = productRestriction?.products;
        this.zipCodeRestriction = zipCodeRestriction;
        this.categoriesOrProdutcts = categoryRestriction
        this.zipCodeRestrictionList = zipCodeRestriction?.zipCodeRangeRestrictions;
        this.dateRestrictionList = dateRestriction;
        this.campaignProps = response.data;
        this.loading = false;
        return response.data;
    }

    async deleteCampaing(uuid: string) {
        this.loading = true;
        const response = await CampaignService.deleteCampaing(uuid);

        if (response.error) {
            message.error(`Erro ao deletar Campanha`);
        } else {
            message.success('Campanha deletado com sucesso!');
        }

        this.campaignProps = response.data;
        this.loading = false;
        return response;
    }

    async updateZipCodeList(data: any) {
        if (!this.zipCodeRestrictionList) {
            this.zipCodeRestrictionList = [];
        }

        this.zipCodeRestrictionList.push(data);
    }


    async removeZipCode(uuid: string) {
        const filterZipCode = this.zipCodeRestrictionList.filter((item: any) => item.uuid !== uuid);
        this.zipCodeRestrictionList = filterZipCode
    }


    async updateCategory(data: any) {
        if (!this.categoryList) {
            this.categoryList = [];
        }

        this.categoryList = data.checked;
    }

    async updateProduct(data: any) {
        const product = {
            active: true,
            name: data?.name,
            skuCode: data?.skuCode,
            uuid: data?.uuid
        }

        if (!this.productList) {
            this.productList = [];
        }

        const findProduct = this.productList.find((item: any) => item.uuid === product.uuid);
        const isProductFound = !!findProduct;

        if (isProductFound) {
            message.error(`Produto já adicionado`);
        } else {
            this.productList.push(product);
        }
    }


    async updateCustomer(data: any) {
        if (!this.customerList) {
            this.customerList = [];
        }

        const findCustomer = this.customerList.find((item: any) => item === removeSpecialCharacters(data.document));
        const isCustomerFound = !!findCustomer;

        if (isCustomerFound) {
            message.error(`Cliente já adicionado`);
        } else {
            this.customerList.push(data.document);
        }
    }

    async removeCustomer(uuidCustomer: any) {
        const filterProduct = this.customerList.filter((item: any) => item !== uuidCustomer);
        this.customerList = filterProduct
    }

    async removeProdut(uuidProduct: string) {
        const filterProduct = this.productList.filter((item: any) => item.uuid !== uuidProduct);
        this.productList = filterProduct
    }

    async createCampaing(data: any) {
        this.loading = true;
        const response = await CampaignService.createCampaing(data);

        if (response.error) {
            this.campaignProps = data;
            message.error(`Erro ao criar Campanha`);
        } else {
            message.success('Campanha cadastrada com sucesso!');
        }

        this.loading = false;
        return response;
    }

    async editCampaing(data: any, uuid: string | undefined) {
        this.loading = true;

        const response = await CampaignService.editCampaing(data, uuid);

        if (response.error) {
            message.error(`Erro ao editar Campanha`);
        } else {
            message.success('Campanha editada com sucesso!');
        }

        this.loading = false;
        return response;
    }

    async deleteCampaingAll(uuid: string) {
        this.loading = true;
        const response = await CampaignService.deleteCampaing(uuid);
        this.campaignProps = response.data;
        this.loading = false;
        return response;
    }

    async getReports(params: any) {
        this.loading = true;
        const response = await CampaignService.getReports(params);
        this.reportsCampaign = response.data;
        this.loading = false;
        return response;
    }

    async searchProductBySkuCode(searchTerm: string) {
        const response = await ProductService.searchProductAllBySku(searchTerm);

        if (!response.error) {
            return response.data
        }
    }

    async updateValue(type: string) {
        const newCampaignProps = { ...this.campaignProps, type: type };
        this.campaignProps = newCampaignProps;
    }
}

const campaingStore = new CampaingStore();
export default campaingStore;