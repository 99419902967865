import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import HomeService from '../services/HomeService';
import { PaginationParams } from '../types';
import { filterComponentsByDate } from '../utils/homeFunctions';


class HomeStore {
    loading: boolean = false;
    homeList: any = [];
    homeProps: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;

    previewList: any = [];

    //Customer
    customerList: any = [];

    //Componentes
    componentsPreview: any = [];
    removeComponentList: any = [];
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    reset() {
        this.homeProps = undefined;
        this.previewList = [];
        this.homeList = [];
        this.componentsPreview = [];
        this.removeComponentList = [];
        this.customerList = []
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await HomeService.getList(params);
        if (!response.error) {
            this.homeList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
            this.sort = params.sort;
        }
        this.loading = false;

        return response.data;
    }


    async getHomeByUuid(uuid: string) {
        this.loading = true;
        const response = await HomeService.getHomeByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar Home');
                return;
            }

            if (response.data.homeComponents.length > 0) {
                const structureArray = response.data.homeComponents.map((item: any) => ({
                    componentUuid: item.homeComponentsId.componentUuid,
                    order: item.componentOrder,
                    mobile: item.mobile,
                    component: item.component,
                    screen: false
                }));

                structureArray.sort((a: any, b: any) => a.order - b.order);
                this.componentsPreview = structureArray;
                this.previewList = structureArray;
            }

            this.homeProps = response.data;
            this.customerList = response.data.customers;
            this.loading = false;
        });

        return response;
    }


    async createHome(home: any) {
        this.loading = true;
        const response = await HomeService.createHome(home);


        if (response.error) {
            message.error(response?.details?.response?.data?.message);
            this.homeProps = home;
        } else {
            if (this.componentsPreview.length > 0) {
                const arrayComponents = this.componentsPreview.map((item: any) => ({
                    componentUuid: item.component.uuid,
                    order: item.order,
                    mobile: item.mobile
                }));

                const payload = { components: arrayComponents };
                await HomeService.registerComponentHome(payload, response.data.uuid);
            }

            // Customer
            if (this.customerList.length > 0) {
                const customer = {
                    customers: this.customerList.map((item: any) => item.uuid)
                };
                await HomeService.registerCustomerHome(customer, response.data.uuid);
            }

            message.success('Home cadastrada com sucesso!');
        }
        this.loading = false;

        return response;
    }

    async editHome(home: any, uuid: string | undefined) {
        this.loading = true;
        const response = await HomeService.editHome(home, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(response?.details?.response?.data?.message);
                this.homeProps = home;
            } else {
                //Component
                if (this.componentsPreview.length > 0) {
                    const arrayComponents = this.componentsPreview.map((item: any) => ({
                        componentUuid: item.component.uuid,
                        order: item.order,
                        mobile: item.mobile
                    }));

                    const payload = { components: arrayComponents };
                    HomeService.registerComponentHome(payload, response.data.uuid);
                }

                if (this.removeComponentList.length > 0) {
                    const arrayComponentsDelete = {
                        components: this.removeComponentList.map((item: any) => {
                            return {
                                componentUuid: item.componentUuid
                            }
                        })
                    };

                    HomeService.deleteComponentHome(arrayComponentsDelete, response.data.uuid);
                }

                //Customer
                if (this.customerList.length > 0) {
                    const customer = {
                        customers: this.customerList.map((item: any) => item.uuid)
                    };
                    HomeService.registerCustomerHome(customer, response.data.uuid);
                }
                message.success('Home editada com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }

    async deleteHome(uuid: string) {
        this.loading = true;
        const response = await HomeService.deleteHome(uuid);

        if (response.error) {
            message.error(`Erro ao deletar Home`);
        } else {
            message.success('Home deletado com sucesso!');
        }
        this.homeProps = response.data;
        this.loading = false;
        return response;
    }


    //Customer
    async updateCustomer(data: any) {
        if (!this.customerList) {
            this.customerList = [];
        }

        const findCustomer = this.customerList.find((item: any) => item.uuid === data?.uuid);
        const isCustomerFound = !!findCustomer;

        if (isCustomerFound) {
            message.error(`Cliente já adicionado`);
        } else {
            this.customerList.push(data);
        }
    }

    async removeCustomer(uuidCustomer: any) {
        const filterCustomer = this.customerList.filter((item: any) => item.uuid !== uuidCustomer);
        this.customerList = filterCustomer
    }

    //Component
    async addComponent(data: any,) {
        if (!this.componentsPreview) {
            this.componentsPreview = [];
        }

        // const findComponent = this.componentsPreview.find((item: any) => item.componentUuid === data?.componentUuid);
        // const isComponentFound = !!findComponent;

        this.componentsPreview.push(data);
        this.previewList.push(data);
        return true
    }


    async removeComponent(uuidComponent: any) {
        const filterComponent = this.componentsPreview.filter((item: any) => item.componentUuid !== uuidComponent);
        const findComponent = this.componentsPreview.find((item: any) => item.componentUuid === uuidComponent);
        this.componentsPreview = filterComponent;
        this.previewList = filterComponent;


        if (findComponent.screen === false) {
            this.removeComponentList.push(findComponent);
        }
    }


    async updateFilterComponent(filterDate: any, mobile: boolean) {
        const copyListPreview = JSON.parse(JSON.stringify(this.componentsPreview));
        const filteredData = filterComponentsByDate(copyListPreview, filterDate);
        this.previewList = filteredData
    }


    async updateModel(model: string) {
        if (!this.homeProps) {
            this.homeProps = { model: '' }; // Inicializa se for undefined
        }

        this.homeProps.model = model;
    }

    async updateHomeOrder(home: any) {
        this.componentsPreview = home;
        this.previewList = home;
    }


    async clearCustomer() {
        this.customerList = [];
    }
}

const homeStore = new HomeStore();
export default homeStore;