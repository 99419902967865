import { makeAutoObservable } from 'mobx';


class UnsavedStore {
    loading: boolean = false;
    unsavedChanges: boolean = true;

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.unsavedChanges = false;
    }

    updateUnsavedChanges(dirty: boolean) {
        this.unsavedChanges = dirty;
    }
}

const unsavedStore = new UnsavedStore();
export default unsavedStore;