import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionPayment } from '../../../permissions/PermissionPayment';
import paymentStore from '../../../stores/paymentStore';
import unsavedStore from '../../../stores/unsavedStore';
import { Installment } from '../../../types/Installment';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import { default as InputNumberComponente } from '../../atoms/InputNumberComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { Link } from 'react-router-dom';

const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <p>Cadastro de Parcelamento</p>,
    },
]

const maxInstallments = 12;

const paymentOption = Array.from({ length: maxInstallments }, (_, index) => {
    const installmentNumber = index + 1;
    return {
        value: installmentNumber.toString(),
        label: `${installmentNumber} Parcela${installmentNumber > 1 ? 's' : ''}`, // Pluraliza se necessário
    };
});

interface OutputData {
    installmentPayment: Installment[];
}


const CreateInstallment: React.FC = observer(() => {
    const { payment, loading } = paymentStore;
    const [installmentArray, setInstallmentArray] = useState<any[]>([]);
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            if (hasPermission(PermissionPayment.GET)) {
                const response = await paymentStore.getPayment();


                if (!response.error) {
                    const order = response.data[0]?.installmentPayment?.sort((a: any, b: any) => a.quantity - b.quantity);
                    setInstallmentArray(order);
                }
            }

        };

        getInformation();

        return () => {
            paymentStore.reset();
        };
    }, []);

    const initialValues: Installment = {
        valueMinimum: payment?.valueMinimum || 0,
        //@ts-ignore
        installment: { value: payment?.installmentPayment?.length, label: '' },
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: Installment) => {
        const transformedData = transformData(values);
        const createProps = {
            ...payment,
            ...transformedData,
            valueMinimum: values.valueMinimum
        };
        paymentStore.editPayment(createProps);
    };

    const generateInstallments = (totalInstallments: number) => {
        setInstallmentArray((prevInstallments) => {
            const updatedInstallments = Array.from({ length: totalInstallments }, (_, index) => {
                const quantity = index + 1;

                // Verifica se já existe uma entrada no array para o "quantity" atual
                const existingInstallment = prevInstallments.find(
                    (installment) => installment.quantity === quantity
                );

                return existingInstallment
                    ? existingInstallment // Reutiliza os dados já preenchidos
                    : { uuid: '', quantity, tax: '' }; // Adiciona uma nova parcela, se não existir
            });

            return updatedInstallments;
        });
    };

    const handleInstallmentChange = (value: any) => {
        generateInstallments(value); // Gera as parcelas ao selecionar
    };

    const handleTaxChange = (index: number, taxValue: number) => {
        const updatedInstallments = [...installmentArray];
        updatedInstallments[index].tax = taxValue;
        setInstallmentArray(updatedInstallments);
    };


    const transformData = (inputData: any): OutputData => {
        const { payment, ...rest } = inputData;

        // Cria uma cópia do installmentArray para manipular
        const updatedInstallments = installmentArray.map((installment, index) => {
            const taxKey = `tax-${index}`; // Gera a chave para cada índice (tax-0, tax-1, ...)
            return {
                ...installment,
                tax: rest[taxKey] !== undefined ? rest[taxKey] : installment.tax, // Atualiza com o valor de tax se estiver presente
            };
        });

        // Constrói o objeto de saída
        const outputData: OutputData = {
            installmentPayment: updatedInstallments,
        };

        return outputData;
    };


    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} textButton={TEXT_BUTTON_HEADER_LIST} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Parcelas" description='Tela para cadastrar o parcelamento'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionPayment.POST, PermissionPayment.PUT, PermissionPayment.GET]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ dirty }) => {
                            useEffect(() => {
                                unsavedStore.updateUnsavedChanges(dirty);
                            }, [dirty]);

                            return (
                                <Form>
                                    <ContentBox className="mb-4 p-6" title="Cadastro de Parcelas" description='Tela para cadastrar o parcelamento'>
                                        <div className="flex w-full gap-4 pb-4">
                                            <SelectComponent
                                                onChange={(value) => handleInstallmentChange(value)}
                                                label="Total de parcelas"
                                                name="installment"
                                                options={paymentOption}
                                            />
                                            <InputNumberComponente label="Parcela mínima" name="valueMinimum" />
                                        </div>
                                    </ContentBox>

                                    <ContentBox className="mb-4 p-6" title="Número de Parcelas" description='Número de parcelas que serão exibidas no Ecommerce'>
                                        {installmentArray && installmentArray?.map((item, index: number) => (
                                            <div key={item.uuid + index} className="flex gap-4 mt-2">
                                                {/* Campo de input para mostrar a quantidade de parcelas */}
                                                <InputNumberComponente
                                                    value={item.quantity}
                                                    disabled
                                                    min={1}
                                                    max={999}
                                                    label="Parcela(s)"
                                                    name={`quantity-${index}`}
                                                />

                                                {/* Campo de input para configurar o juros (tax) de cada parcela */}
                                                <InputNumberComponente
                                                    value={item.tax}
                                                    min={0}
                                                    max={100}
                                                    onChange={(value) => handleTaxChange(index, value)} // Atualiza o juros dessa parcela
                                                    label="Juros(%)"
                                                    name={`tax-${index}`}
                                                />
                                            </div>
                                        ))}
                                    </ContentBox >

                                    <div className="flex justify-end">
                                        <Permission permissionKeys={[PermissionPayment.PUT, PermissionPayment.POST]} buttonType>
                                            <ActionButton htmlType="submit" actionType="primary">Atualizar</ActionButton>
                                        </Permission>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik >
                </Permission>
            }
        </>
    );
});

export default CreateInstallment;