import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionAuthority } from '../../../permissions/PermissionAuthority';
import { PermissionAuthorityGroups } from '../../../permissions/PermissionAuthorityGroups';
import authoritiesStore from '../../../stores/authoritiesStore';
import perfisStore from '../../../stores/perfisStore';
import unsavedStore from '../../../stores/unsavedStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import CheckboxComponent from '../../atoms/CheckboxComponent';
import InputComponent from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import SwitchComponent from '../../atoms/SwitchComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import './styles.scss';


const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <Link to="/perfis">Listagem de Perfis</Link>,
    },
    {
        title: <p>Cadastro de Perfil</p>,
    },
];

const CreatePerfil: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { authoritiesList, loadingAuthorities } = authoritiesStore;
    const { perfil, loading } = perfisStore;
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const { hasPermission } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            let response

            if (hasPermission(PermissionAuthority.GET)) {
                response = await authoritiesStore.getList();
            }

            // Filtra as permissões que devem ser exibidas
            const permissionsForSelectedGroup = response?.filter((auth: any) => auth.show === true && auth.groupedBy === 'Produtos');
            setPermissions(permissionsForSelectedGroup);
        };

        const fetchPerfilData = async () => {
            if (uuid && hasPermission(PermissionAuthorityGroups.GET_UUID)) {
                const result = await perfisStore.getPerfilByUuid(uuid);
                setSelectedItems(result.authorities)
            }
        };

        fetchPerfilData();
        fetchData();

        return () => {
            perfisStore.reset();
        };
    }, []);


    const initialValues = {
        permissions: [],
        name: perfil?.name || '',
        active: perfil?.active || '',
    };

    const handleSubmit = async (values: { permissions: string[], name: string, active: boolean }) => {
        let response;

        const authoritiesArray = selectedItems.map((item: any) => ({
            uuid: item.uuid
        }));

        const payload = {
            deleted: false,
            "@class": "br.com.stoom.kernel.model.persistence.AuthorityGroups",
            name: values.name,
            authorities: authoritiesArray,
            active: values.active || false,
        };

        if (uuid) {
            response = await perfisStore.editPerfil(payload, uuid);
        } else {
            response = await perfisStore.createPerfil(payload);
        }

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/perfis`);
    };

    const uniqueGroups = authoritiesList ? [...new Set(authoritiesList?.filter((auth: any) => auth.show).map((auth: any) => auth.groupedBy))] : [];
    const sortedItems = uniqueGroups ? [...uniqueGroups].sort((a: any, b: any) => a.localeCompare(b)) : [];
    const sortedItemsPermission = permissions ? [...permissions].sort((a: any, b: any) => a.description.localeCompare(b.description)) : [];

    const handleGroupClick = (group: any) => {
        const permissionsForSelectedGroup = authoritiesList?.filter((auth: any) => auth.groupedBy === group);
        setPermissions(permissionsForSelectedGroup);
    };

    const countItemsInGroup = (group: string) => {
        return selectedItems?.filter((item: any) => item.groupedBy === group).length;
    };


    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionAuthorityGroups.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Perfis" description='Tela para cadastro de Perfil'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionAuthorityGroups.POST, PermissionAuthorityGroups.PUT, PermissionAuthorityGroups.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ dirty }) => {
                            const onChange = (item: any) => {
                                setSelectedItems((prevSelectedItems: any) => {
                                    if (prevSelectedItems.some((selectedItem: any) => selectedItem.uuid === item.uuid)) {
                                        return prevSelectedItems.filter((selectedItem: any) => selectedItem.uuid !== item.uuid);
                                    } else {
                                        return [...prevSelectedItems, item];
                                    }
                                });
                            };

                            useEffect(() => {
                                unsavedStore.updateUnsavedChanges(dirty);
                            }, [dirty]);

                            return (
                                <Form>
                                    <div>
                                        <ContentBox className="mb-4 p-6" title="Cadastro de Perfis" description='Tela para cadastro de Perfil' actions={
                                            <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
                                        }>
                                            <div className="flex gap-3">
                                                <InputComponent label="Nome do Perfil" name="name" id="name" />
                                            </div>
                                        </ContentBox>
                                    </div>

                                    {!loadingAuthorities &&
                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-4">
                                                <Permission permissionKeys={[PermissionAuthority.GET]} fallback={<PermissionComponent />}>
                                                    <ContentBox className="p-6" description='Selecione um dos grupos abaixo para configurar as permissões.'>
                                                        <div className="flex flex-col gap-3 perfil-container">
                                                            {sortedItems && sortedItems?.map((group: any) => (
                                                                <div className="cursor-pointer flex gap-2 items-center" key={group} onClick={() => handleGroupClick(group)}>
                                                                    <div className={`rounded w-6 flex items-center justify-center text-sm ${countItemsInGroup(group) > 0 ? 'bg-primary-color text-white' : 'bg-gray-200 text-black'
                                                                        }`}>
                                                                        {countItemsInGroup(group)} {/* Contador de itens */}
                                                                    </div>
                                                                    <p className="text-sm">{group}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </ContentBox>
                                                </Permission>
                                            </div>
                                            <div className="col-span-8">
                                                <Permission permissionKeys={[PermissionAuthority.GET]} fallback={<PermissionComponent />}>
                                                    <ContentBox className="p-6" title={permissions && permissions[0]?.groupedBy} description='Escolha suas opções preferidas abaixo para uma experiência ainda mais personalizada!'>
                                                        <div className="flex flex-col gap-2">
                                                            {sortedItemsPermission && sortedItemsPermission.map((item: any, index: number) => (
                                                                <div key={index}>
                                                                    <CheckboxComponent label={`${item.description} (${item.action})`} key={item.uuid} onChange={() => onChange(item)} checked={selectedItems.some((selected: any) => selected?.uuid === item.uuid)} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </ContentBox>
                                                </Permission>

                                                <div className="flex justify-end pt-4">
                                                    <Permission permissionKeys={[PermissionAuthorityGroups.PUT, PermissionAuthorityGroups.POST]} buttonType>
                                                        <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                                    </Permission>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {loadingAuthorities &&
                                        <ContentBox className="mb-4 p-6">
                                            <LoadingComponent />
                                        </ContentBox>
                                    }
                                </Form>
                            );
                        }}
                    </Formik>
                </Permission>
            }
        </>
    );
});

export default CreatePerfil;
