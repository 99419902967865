import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams from '../../../hooks/useQueryParams';
import { PermissionSize } from '../../../permissions/PermissionSize';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import LogCampaign from './components/LogCampaign';
import LogChannel from './components/LogChannel';
import LogCupom from './components/LogCupom';
import LogCustomer from './components/LogCustomer';
import LogOrder from './components/LogOrder';
import LogPrice from './components/LogPrice';
import LogProduct from './components/LogProduct';
import LogUser from './components/LogUser';

// BreadCrumb
const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <p>Listagem de Logs</p>,
    },
]

const ListLog: React.FC = observer(() => {
    const [queryParams, setQueryParams] = useQueryParams();

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Usuário',
            children: <LogUser setQueryParams={setQueryParams} queryParams={queryParams} />,
        },
        {
            key: '2',
            label: 'Produtos',
            children: <LogProduct setQueryParams={setQueryParams} queryParams={queryParams} />,
        },
        {
            key: '3',
            label: 'Clientes',
            children: <LogCustomer setQueryParams={setQueryParams} queryParams={queryParams} />,
        },
        {
            key: '4',
            label: 'Preço',
            children: <LogPrice setQueryParams={setQueryParams} queryParams={queryParams} />,
        },
        // {
        //     key: '5',
        //     label: 'Banners',
        //     children: <LogBannerFile />,
        // },
        {
            key: '6',
            label: 'Cupom',
            children: <LogCupom setQueryParams={setQueryParams} queryParams={queryParams} />,
        },
        {
            key: '7',
            label: 'Campanha',
            children: <LogCampaign setQueryParams={setQueryParams} queryParams={queryParams} />,
        },
        {
            key: '8',
            label: 'Canal',
            children: <LogChannel setQueryParams={setQueryParams} queryParams={queryParams} />,
        },
        {
            key: '9',
            label: 'Pedidos',
            children: <LogOrder setQueryParams={setQueryParams} queryParams={queryParams} />,
        },
    ];

    const onChange = (key: string) => {
        setQueryParams({});
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionSize.POST} textButton={TEXT_BUTTON_HEADER_CREATE} />

            <Tabs
                className="mb-0"
                type="card"
                items={items}
                onChange={onChange}
            />
        </>
    );
});

export default ListLog;