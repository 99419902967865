import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import productStore from '../../../../stores/productStore';
import InputComponent from '../../../atoms/InputComponet';
import InputNumberComponente from '../../../atoms/InputNumberComponet';
import SwitchComponent from '../../../atoms/SwitchComponent';
import TextAreaComponent from '../../../atoms/TextAreaComponent';
import ContentBox from '../../../molecules/ContentBox';
import SelectedCategories from '../SelectedCategories';


const ProdutoInformation: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { product } = productStore;
    const [active, setActive] = useState(product.active);

    const ruleActiveProduct = (item: any) => {
        if (product && product?.prices?.length === 0) {
            message.error('Para ativar um produto cadastre um preço');
            setActive(false);
            return false;
        }

        if (product?.files.length === 0) {
            message.error('Para ativar um produto cadastre uma imagem');
            setActive(false);
            return false;
        }

        setActive(item);
        return item
    }

    useEffect(() => {
        if (product) {
            setActive(product.active)
        }
    }, [])

    return (
        <div>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Cadastro de Produto" description='Tela para cadastro de Produto' actions={
                <>
                    <SwitchComponent value={active} defaultChecked={active} name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" condition={(item) => ruleActiveProduct(item)} />
                </>
            }>

                <div className="w-full flex flex-col gap-2">
                    <div className="flex gap-4">
                        <InputComponent maxLength={100} showMaxLength={true} label="Nome" name="name" id="name" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent maxLength={100} showMaxLength={true} label="Nome Curto" name="shortName" id="shortName" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="EAN" name="barcode" id="barcode" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Link do vídeo" name="videoPath" id="videoPath" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent disabled label="Slug" name="slug" id="slug" />
                        <InputComponent label="Codigo SKU" name="skuCode" id="skuCode" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Meta Canônica" name="metaCanonical" id="metaCanonical" />
                        <InputComponent label="Meta Keywords" name="metaKeywords" id="metaKeywords" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Meta Title" name="metaTitle" id="metaTitle" />
                        <InputComponent label="Meta Description" name="metaDescription" id="metaDescription" />
                    </div>

                    <div className="flex gap-4">
                        <TextAreaComponent maxLength={350} showMaxLength={true} label="Descrição curta" name="shortDescription" id="shortDescription" />
                    </div>

                    <div className="flex gap-4">
                        <TextAreaComponent maxLength={1500} showMaxLength={true} label="Descrição completa" name="description" id="description" />
                    </div>
                </div>
            </ContentBox>

            <div className="grid grid-cols-12 gap-4" >
                <div className="col-span-6">
                    <ContentBox className="mb-4 p-6" title="Ficha Tecnica" description='Tela para cadastro de Produto'>
                        <div className="w-full flex flex-col gap-2">
                            <div className="flex gap-4">
                                <InputNumberComponente label="Peso Liquido" name="netWeight" id="netWeight" />
                                <InputNumberComponente label="Peso Bruto" name="weight" id="weight" />
                            </div>

                            <div className="flex gap-4">
                                <InputNumberComponente label="Altura (cm)" name="height" id="height" />
                                <InputNumberComponente label="Profundidade (cm)" name="length" id="length" />
                            </div>

                            <div className="flex gap-4">
                                <InputNumberComponente label="Peso envio" name="shippingWeight" id="shippingWeight" />
                                <InputNumberComponente label="Altura envio" name="shippingHeight" id="shippingHeight" />
                            </div>
                            <div className="flex gap-4">
                                <InputNumberComponente label="Largura envio" name="shippingWidth" id="shippingWidth" />
                                <InputNumberComponente label="Largura (cm)" name="width" id="width" />
                            </div>

                            <div className="flex gap-4">
                                <InputNumberComponente label="Cubagem" name="cubage" id="cubage" />
                            </div>
                        </div>
                    </ContentBox>
                </div>
                <div className="col-span-6">
                    <SelectedCategories />
                </div>
            </div>
        </div>
    );
});

export default ProdutoInformation;