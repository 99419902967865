import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Image, Space, Table, Tag, Tooltip } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { COLOR_TOOLTIP, DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionProducts } from '../../../permissions/PermissionProducts';
import categoryStore from '../../../stores/categoryStore';
import productStore from '../../../stores/productStore';
import { Product } from '../../../types/Product';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import { getStatusColor } from '../../../utils/statusColorMapper';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import PermissionComponent from '../../atoms/PermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalProducts from '../../organisms/ModalProducts';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import InputComponent from '../../atoms/InputComponet';
import { StatusActive } from '../../../utils/selects';

interface DataType {
    active: boolean | React.Key;
    skuCode: any;
    sku: any;
    document: string;
    type: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    name?: string,
    active?: string,
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <p>Listagem de Produtos por Categoria</p>,
    },
]

const ListProductByCategory: React.FC = observer(() => {
    const { productListByCategory, loading, page, totalElements, defaultparams, sort, size } = productStore;
    const { categoryListSelect } = categoryStore;
    const [isModalProduct, setModalProduct] = useState(false);
    const [itemSelectedProduct, setItemSelectedProduct] = useState<any>();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useQueryParams();
    const listRouter = () => { navigate(`/produto/cadastro`); }
    const { hasPermission, isColumnVisible } = useAuth();

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        name: 'name',
        active: 'active',
        skuCode: 'skuCode',
        category: 'category'
    };

    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings, ":", ";");
        const search = params.search;
        const match = search.match(/category:"(.*?)"/);
        const categoryName = match ? match[1] : null;

        const fetchData = async () => {
            if (hasPermission(PermissionProducts.GET)) {
                if (categoryName !== undefined) {
                    // @ts-ignore
                    await productStore.getProductByCategory(categoryName, params);
                }
            }
            fetchDataCategory();
        };

        fetchData();
    }, []);


    const fetchDataCategory = async () => {
        await categoryStore.getListCategorySelect(true);
    };

    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Imagem',
            dataIndex: 'files',
            key: 'files',
            width: 55,
            render: (files) => {
                return (
                    <Image
                        width={50}
                        src={files.length > 0 && files[0].path}
                    />
                );
            },
        },
        {
            title: 'Nome do Produto',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            filters: getUniqueFilters(productListByCategory, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
            render: (name) => {
                return (
                    <Tooltip color={COLOR_TOOLTIP} title={name}>
                        <span >{name}</span>
                    </Tooltip>
                );
            },
        },
        {
            title: 'Sku',
            dataIndex: 'skuCode',
            key: 'skuCode',
            sorter: true,
            filters: getUniqueFilters(productListByCategory, 'skuCode'),
            onFilter: (value, record) => record.skuCode.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('skuCode', queryParams.sort),
            render: (name) => {
                return (
                    <Tooltip color={COLOR_TOOLTIP} title={name}>
                        <div>
                            <span >{name}</span>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            render: (text) => <p>{formatDateString(text)}</p>,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
        },
        {
            title: 'Ativos',
            dataIndex: 'active',
            key: 'active',
            sorter: true,
            filters: [
                { text: 'Ativo', value: true },
                { text: 'Inativo', value: false },
            ],
            onFilter: (value, record) => record.active === value,
            defaultSortOrder: getSortOrder('active', queryParams.sort),
            render: (active) => {
                let text = active ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Visualizar',
            dataIndex: 'view',
            key: 'view',
            align: 'center',
            width: 50,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <div onClick={() => showModalProduct(record)}>
                        <div><EyeOutlined /></div>
                    </div>
                </Space>
            ),
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 50,
            hidden: !isColumnVisible(PermissionProducts.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => navigate(`/produto/editar/${record.uuid}`)}>
                    <a href={`/produto/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
    ];


    const showModalProduct = (record: any) => {
        setItemSelectedProduct(record);
        setModalProduct(true);
    };

    // Interação da Tabela como sorte
    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        const paramsInitial = buildParamsFromQuery(queryParams, mappings, ":", ";");
        const search = paramsInitial.search;
        const match = search.match(/category:"(.*?)"/);
        const categoryName = match ? match[1] : null;

        //@ts-ignore
        productStore.getProductByCategory(categoryName, params);
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const filters = buildParamsFromQuery(queryParams, mappings, ":", ";");

        const params = {
            size: newPageSize || queryParams.size || pagination.pageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort, // Use o sort da tabela se disponível
            search: filters.search || queryParams.search || '',
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
            search: params.search,
        });

        return params;
    };


    const initialValues = {
        active: typeof queryParams.active === 'string'
            ? { value: queryParams.active, label: queryParams.active }
            : { value: '', label: '' },
        name: Array.isArray(queryParams.name) ? queryParams.name[0] || '' : queryParams.name || '',
        skuCode: Array.isArray(queryParams.skuCode) ? queryParams.skuCode[0] || '' : queryParams.skuCode || '',
        category: typeof queryParams.category === 'string'
            ? { value: queryParams.category, label: queryParams.category }
            : { value: '', label: '' },
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: { name: string; active: { value: string; label: string }, skuCode: string, category: { value: string; label: string } }) => {
        const newParams: any = {
            active: values?.active?.value || undefined,
            skuCode: values?.skuCode || undefined,
            name: values?.name || undefined,
            category: values?.category?.value || undefined
        };

        const search = [
            values.active.value ? `,active:"${values?.active?.value}"` : null,
            values.name ? `;name;"${values?.name}"` : null,
            values.skuCode ? `;skuCode;"${values?.skuCode}"` : null,
        ]
            .filter(Boolean)
            .join(' ');

        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sort || 'created,desc',
            search,
        };

        setQueryParams(newParams);
        const slug = values?.category?.value;


        //@ts-ignore
        await productStore.getProductByCategory(slug, params);
    };

    // Limpa os dados do formulario
    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm(); // Limpa os valores do formulário
        setQueryParams({}); // Limpa a URL

        //@ts-ignore
        await productStore.getProductByCategory(null, defaultparams);
    };


    const handleExport = async () => {
        const params = buildParamsFromQuery(queryParams, mappings, ":", ";");
        const search = params.search;
        const match = search.match(/category:"(.*?)"/);
        const categoryName = match ? match[1] : null;

        const exportParams = {
            ...params,
            size: 999999,
        }

        //@ts-ignore
        const dataExport = await productStore.getProductByCategory(categoryName, exportParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: Product) => ({
                name: item.name,
                shortName: item.shortName,
                active: item.active ? "Sim" : "Não",
                searchable: item.searchable ? "Sim" : "Não",
                display: item.display ? "Sim" : "Não",
                barcode: item.barcode,
                videoPath: item.videoPath,
                file: item.file?.path,
                slug: item.slug,
                skuCode: item.skuCode,
                metaCanonical: item.metaCanonical,
                metaKeywords: item.metaKeywords,
                metaTitle: item.metaTitle,
                metaDescription: item.metaDescription,
                shortDescription: item.shortDescription,
                description: item.description,
                weight: item.weight,
                netWeight: item.netWeight,
                height: item.height,
                length: item.length,
                shippingWeight: item.shippingWeight,
                shippingHeight: item.shippingHeight,
                shippingWidth: item.shippingWidth,
                width: item.width,
                cubage: item.cubage,
                categories: item.categories,
                created: formatDateString(item.created ?? ""),
            }));

            exportToExcel(listExport, [
                'Nome',
                'Nome Curto',
                'Produto esta ativo?',
                'Indexar na Busca?',
                'Exibir na vitrine?',
                'EAN',
                'Link do video',
                'Manual do produto',
                'Slug',
                'Codigo SKU',
                'Meta Canônica',
                'Meta Keywords',
                'Meta Title',
                'Meta Description',
                'Descrição curta',
                'Descrição completa',
                'Peso Liquido',
                'Peso Bruto',
                'Altura (cm)',
                'Profundidade (cm)',
                'Peso envio',
                'Altura envio',
                'Largura envio',
                'Largura (cm)',
                'Cubagem',
                'Categoria',
                'Data de criação',
            ], "Tabela de Produto.xlsx");
        }
    };


    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionProducts.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <Permission permissionKeys={[PermissionProducts.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Listagem de Produtos por Categoria" description='Tela de listagem de Produtos filtrado por categorias'>
                    {/* @ts-ignore */}
                    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (
                            <Form>
                                <div className="flex items-end w-full gap-4 pb-4">
                                    <div className="w-full">
                                        <InputComponent label="Nome" name="name" id="name" />
                                    </div>
                                    <div className="w-full">
                                        <InputComponent label="SKU" name="skuCode" id="skuCode" />
                                    </div>
                                    <div className="w-full">
                                        <SelectComponent label="Status" name="active" options={StatusActive} />
                                    </div>
                                </div>

                                <div className="pb-4">
                                    <SelectComponent
                                        label="Categoria"
                                        name="category"
                                        options={categoryListSelect}
                                    />
                                </div>

                                <div className="flex items-end gap-4 justify-end ">
                                    <Button onClick={() => handleClearAndSearch(resetForm)}>
                                        Limpar
                                    </Button>
                                    <Button htmlType="submit" type="primary">Filtrar</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6" description='Tela de listagem de Produtos por Categoria' actions={
                    <>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={productListByCategory && productListByCategory.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        loading={loading}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['40', '60', '80'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            {isModalProduct && <ModalProducts isModel={true} setIsModalOpen={setModalProduct} isModalOpen={isModalProduct} product={itemSelectedProduct} />}
        </>
    );
});

export default ListProductByCategory;