import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import PaymentService from '../services/PaymentService';
import { Installment } from '../types/Installment';

class PaymentStore {
    loading: boolean = false;
    payment: Installment | undefined;

    reset() {
        this.payment = undefined;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getPayment() {
        this.loading = true;
        const response = await PaymentService.getPayment();

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar Parcelamento');
            } else {
                this.payment = response.data[0];
            }
            this.loading = false;
        });

        return response;
    }

    async editPayment(data: any) {
        this.loading = true;
        const response = await PaymentService.editPayment(data);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao editar Dados de Parcelamento');
            } else {
                message.success('parcelamento editado com sucesso!');
                this.getPayment();
            }

            this.loading = false;
        });
        return response;
    }
}

const paymentStore = new PaymentStore();
export default paymentStore;