// src/components/organisms/LoginForm/index.tsx
import { Button } from 'antd';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction, useState } from 'react';
import authStore from '../../../../stores/authStore';
import validationSchema from './validationSchema';
import InputPassword from '../../../atoms/InputPassword';
import { validatePasswordCriteria } from './functionPassword';

interface ChangePasswordProps {
    setStep: Dispatch<SetStateAction<number>>;
    email: string;
    token: string;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ setStep, email, token }) => {
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>();
    const [passwordCriteria, setPasswordCriteria] = useState({
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasMinLength: false,
        passwordsMatch: false, // Adicionando a validação de senhas iguais
        isValid: false,        // Adicionando a validação geral
    });

    const initialValues = {
        newPassword: '',
        confirmNewPassword: '',
    };

    const handleSubmit = async (values: { newPassword: string }) => {
        const response = await authStore.changePassword(email, token, values.newPassword);

        if (!response.error) {
            setStep(0);
        }
    };

    const handlePasswordChange = (password: string,) => {
        setNewPassword(password)
        const criteria = validatePasswordCriteria(password, confirmPassword);
        criteria && setPasswordCriteria(criteria);
    };

    const handlePasswordChangeConfirm = (password: string,) => {
        setConfirmPassword(password);
        const criteria = validatePasswordCriteria(newPassword, password);
        criteria && setPasswordCriteria(criteria);
    };

    return (

        <div className="flex flex-col items-center justify-center rounded-xl p-8 bg-white min-w-96" >
            <div className="flex flex-col items-center justify-center pb-6">
                <p className="font-semibold text-lg">Recuperação de Senha</p>
                <p className="text-slate-400 text-sm">Redefinir senha com stoom.</p>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <div className="w-full">
                        <Form className="flex flex-col justify-start gap-4 w-full">
                            <InputPassword onChange={(e) => handlePasswordChange(e)} label="Nova Senha" name="newPassword" id="newPassword" type='password' />
                            <InputPassword onChange={(e) => handlePasswordChangeConfirm(e)} label="Confirmar Senha" name="confirmNewPassword" id="confirmNewPassword" type='password' />

                            <div className="w-full max-w-80 text-sm">
                                <p>A senha deve conter:</p>
                                <p style={{ color: passwordCriteria.hasUppercase ? "green" : "red" }}>
                                    - Letras maiúsculas
                                </p>
                                <p style={{ color: passwordCriteria.hasLowercase ? "green" : "red" }}>
                                    - Letras minúsculas
                                </p>
                                <p style={{ color: passwordCriteria.hasNumber ? "green" : "red" }}>
                                    - Números
                                </p>
                                <p style={{ color: passwordCriteria.hasSpecialChar ? "green" : "red" }}>
                                    - Caracteres especiais (Ex: @ # *)
                                </p>
                                <p style={{ color: passwordCriteria.hasMinLength ? "green" : "red" }}>
                                    - Pelo menos 10 caracteres
                                </p>
                                <p style={{ color: passwordCriteria.passwordsMatch ? "green" : "red" }}>
                                    - Senha deve ser Igual
                                </p>
                            </div>

                            <div className="pt-2 w-full">
                                <Button style={{ color: "#fff" }} disabled={!passwordCriteria.isValid} className="w-full mb-2" htmlType="submit" type="primary">Alterar Senha</Button>
                                <Button className="w-full" htmlType="submit" type="default" onClick={() => setStep(1)}>Voltar</Button>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default ChangePassword;