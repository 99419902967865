import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import MaterialService from '../services/MaterialService';
import { PaginationParams } from '../types';
import { message } from 'antd';
import { Material } from '../types/Material';
import { transformToValueLabel } from '../utils/functions';
import { mapErrorMessage } from '../utils/mapErrorMensage';


class MaterialStore {
    loading: boolean = false;
    materialList: Material[] = [];
    material: Material | undefined;
    materialSelectList: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'code,desc',
        search: '',
    }

    reset() {
        this.material = undefined;
        this.materialSelectList = [];
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await MaterialService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.materialList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
                this.sort = params.sort;
                this.materialSelectList = transformToValueLabel(response.data.content, 'uuid', 'code');
            }
            this.loading = false;
        });
        return response;
    }

    async createMaterial(material: Material) {
        this.loading = true;
        const response = await MaterialService.createMaterial(material);

        runInAction(() => {
            if (response.error) {
                message.error(mapErrorMessage(response?.details?.response?.data?.message));
                this.material = material;
            } else {
                message.success('Material cadastrado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }

    async editMaterial(material: Material, uuid: string | undefined) {
        this.loading = true;
        const response = await MaterialService.editMaterial(material, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(mapErrorMessage(response?.details?.response?.data?.message));
                this.material = material;
            } else {
                message.success('Material editado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }

    async getMaterialByUuid(uuid: string) {
        this.loading = true;
        const response = await MaterialService.getMaterialByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar Material`);
                return;
            }
            this.material = response.data;
            this.loading = false;
        });

        return response;
    }

    async deleteMaterial(uuid: string) {
        this.loading = true;
        const response = await MaterialService.deleteMaterial(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao deletar Material');
            } else {
                message.success('Material deletado com sucesso!');
                this.material = undefined;
            }
            this.loading = false;
        });

        return response;
    }

    async deleteMaterialAll(uuid: string) {
        this.loading = true;
        const response = await MaterialService.deleteMaterial(uuid);

        runInAction(() => {
            if (!response.error) {
                this.material = undefined;
            }
            this.loading = false;
        });

        return response;
    }
}

const materialStore = new MaterialStore();
export default materialStore;