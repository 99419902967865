export const getActionLog = (action: string): string => {
    switch (action) {
        case 'DEL':
            return 'Deletar';
        case 'UPDATE':
            return 'Atualização';
        case 'MOD':
            return 'Atualização';
        case 'ADD':
            return 'Criação';
        case 'CREATE':
            return 'Criação';
        case 'DELETE':
            return 'Deletar';
        default:
            return '';
    }
};


export const getActionColor = (action: string): string => {
    switch (action) {
        case 'DEL':
            return 'red';
        case 'UPDATE':
            return 'blue';
        case 'MOD':
            return 'blue';
        case 'CREATE':
            return 'green';
        case 'ADD':
            return 'green';
        case 'DELETE':
            return 'red';
        default:
            return '';
    }
};



