import { EyeOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Space, Table, Tag } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { ParsedQuery } from 'query-string';
import React, { useEffect, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/config';
import logStore from '../../../../../stores/logStore';
import { formatDateString } from '../../../../../utils/dateFunctions';
import { exportToExcel } from '../../../../../utils/excelFunction';
import { convertToDayjs, formattedDates, getFirstFilterDates } from '../../../../../utils/functions';
import { getActionColor, getActionLog } from '../../../../../utils/statusLogMapper';
import ActionButton from '../../../../atoms/ButtonComponent';
import DatePickerRangeComponet from '../../../../atoms/DatePickerRangeComponet';
import InputComponent from '../../../../atoms/InputComponet';
import ContentBox from '../../../../molecules/ContentBox';
import ModalLogComparativo from '../ModalLogComparativo';

interface DataType {
    file: boolean | React.Key;
    name: boolean | React.Key;
    type: boolean | React.Key;
    idUser: any;
    email: any;
    username: any;
    description: string;
    code: string;
    uuid: string;
    key: React.Key;
}


interface QueryParams {
    modifiedBy: undefined;
    endDate: any;
    startDate: any;
    size?: number;
    page?: number;
    sort?: string;
}

interface LogChannelProps {
    setQueryParams: (params: Record<string, any>) => void;
    queryParams: ParsedQuery<string>;
}

const LogChannel: React.FC<LogChannelProps> = observer(({ setQueryParams, queryParams }) => {
    const { logChannel, loading, pageChannel, totalElementsChannel, sizeChannel } = logStore;
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [initialDate, finalDate] = getFirstFilterDates();

    const params = {
        entityName: 'TresCoracoesChannel',
        startDate: queryParams.startDate
            ? queryParams.startDate
            : initialDate + "T00:00:00.556",
        endDate: queryParams.endDate
            ? queryParams.endDate
            : finalDate + "T23:59:59.999",
        page: queryParams.page || 0,
        size: queryParams.size || DEFAULT_PAGE_SIZE,
        modifiedBy: queryParams.modifiedBy || undefined,
    }

    useEffect(() => {
        const fetchData = async () => {
            await logStore.getListChannel(params);
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Código',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Canal',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Usuario',
            dataIndex: 'modifiedBy',
            key: 'modifiedBy',
        },
        {
            title: 'Modificação',
            dataIndex: 'modifiedDate',
            key: 'modifiedDate',
            render: (item) => <p>{formatDateString(item)}</p>,
        },
        {
            title: 'Ação',
            dataIndex: 'operation',
            key: 'operation',
            render: (item) => {
                return (
                    <>{item && <Tag color={getActionColor(item)}> <p>{getActionLog(item)}</p></Tag>}  </>
                );
            },
        },
        {
            title: 'Visualizar',
            dataIndex: 'view',
            key: 'view',
            align: 'center',
            width: 30,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <div onClick={() => showModal(record)}>
                        <div><EyeOutlined /></div>
                    </div>
                </Space>
            ),
        },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleExport = async () => {
        const dataExport = await logStore.getListChannel(params);

        if (dataExport) {
            const listExport = dataExport?.data?.content.map((item: any) => ({
                actionType: getActionLog(item?.operation),
                userDetails: item?.id,
                user: item?.modifiedBy,
                modified: formatDateString(item.modifiedDate ?? ""),
            }));

            exportToExcel(listExport, ["Ação", "ID Usuário", "Usuário", "Modificação"], "Log de Canal.xlsx");
        }
    };


    // Faz o filtro da tabela
    const handleSubmit = async (values: { modifiedBy: string, date: any }) => {
        const formattedDate = formattedDates(values?.date, 'YYYY-MM-DD');
        const dateInitial = formattedDate[0];
        const dateFinal = formattedDate[1];

        const newParams: any = {
            modifiedBy: values?.modifiedBy || undefined,
        };

        // Cria os parâmetros base
        const params: Record<string, any> = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            entityName: 'TresCoracoesChannel',
            modifiedBy: values?.modifiedBy || undefined,
            startDate: dateInitial ? `${dateInitial}T00:00:00` : undefined,
            endDate: dateFinal ? `${dateFinal}T23:59:59` : undefined,
        };

        Object.keys(params).forEach((key) => {
            if (params[key] === undefined) {
                delete params[key];
            }
        });

        setQueryParams(newParams);

        // //@ts-ignore
        await logStore.getListChannel(params);
    };

    // Limpa os dados do formulario
    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm();
        setQueryParams({});

        const clearParams = {
            entityName: 'TresCoracoesChannel',
            startDate: initialDate + "T00:00:00.556",
            endDate: finalDate + "T23:59:59.999",
            page: 0,
            size: DEFAULT_PAGE_SIZE,
            sort: 'created',
        }

        await logStore.getListChannel(clearParams);
    };


    const initialValues = {
        modifiedBy: Array.isArray(queryParams.modifiedBy) ? queryParams.modifiedBy[0] || '' : queryParams.modifiedBy || '',
        date:
            queryParams.startDate && queryParams.endDate
                ? [convertToDayjs(queryParams.startDate), convertToDayjs(queryParams.endDate)]
                : [convertToDayjs(initialDate), convertToDayjs(finalDate)],
    };

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        //@ts-ignore
        const params = buildParams(pagination, sorter, queryParams);
        await logStore.getListChannel(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const params = {
            entityName: 'TresCoracoesChannel',
            startDate: queryParams.startDate
                ? queryParams.startDate
                : initialDate + "T00:00:00.556",
            endDate: queryParams.endDate
                ? queryParams.endDate
                : finalDate + "T23:59:59.999",
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            modifiedBy: queryParams.modifiedBy || undefined,
            sort: 'created',
        }

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
        });

        return params;
    };

    return (
        <>
            <ContentBox borderCustom='rounded-b-lg' className="mb-4 p-6" title="Log de Canal" description='Tela de logs de canal'>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                    {({ resetForm }) => (
                        <Form>
                            <div className="flex items-end w-full gap-4 pb-4">
                                <div className="w-full">
                                    <InputComponent label="Usuário" name="modifiedBy" id="modifiedBy" />
                                </div>
                                <div className="w-full">
                                    <DatePickerRangeComponet label="Data" name="date" />
                                </div>
                                <div className="flex items-end gap-4 justify-end ">
                                    <Button onClick={() => handleClearAndSearch(resetForm)}>
                                        Limpar
                                    </Button>
                                    <Button htmlType="submit" type="primary">Filtrar</Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ContentBox>


            <ContentBox className="mb-4 p-6" description='Tela de logs de Canal' actions={
                <>
                    <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                </>
            }>

                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={logChannel.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    onChange={onChange}
                    size="middle"
                    bordered={false}
                    pagination={{
                        current: pageChannel + 1,
                        pageSize: sizeChannel,
                        total: totalElementsChannel,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '40', '60'],
                    }}
                    showSorterTooltip={{ target: 'sorter-icon' }}
                />
            </ContentBox>

            {isModalOpen && <ModalLogComparativo setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} itemSelected={itemSelected} />}
        </>
    );
});

export default LogChannel;