import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, message, Space, Table, Tag } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionAuthorityGroups } from '../../../permissions/PermissionAuthorityGroups';
import perfisStore from '../../../stores/perfisStore';
import { Profile } from '../../../types/Profile';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import { getStatusColor } from '../../../utils/statusColorMapper';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    active: boolean | React.Key;
    created: any;
    name: any;
    uuid: any;
    key: React.Key;
    nameTag: string;
    responsive: boolean;
    tag: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <p>Listagem de Perfis</p>,
    },
]

interface QueryParams {
    name?: string;
    code?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

const ListPerfil: React.FC = observer(() => {
    const { perfilList, loading, page, totalElements, defaultparams, sort, size } = perfisStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const navigate = useNavigate();
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const listRouter = () => { navigate(`/perfis/cadastro`); }
    const { hasPermission, isColumnVisible } = useAuth();

    const initialValues = {
        name: queryParams.name || '',
    };

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        name: 'name',
    };

    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionAuthorityGroups.GET)) {
                await perfisStore.getList(params);
            }
        };
        fetchData();
    }, []);

    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            filters: getUniqueFilters(perfilList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            onFilter: (value, record) => record.created.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
            render: (text) => {
                return (
                    //@ts-ignore
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Ativos',
            dataIndex: 'active',
            key: 'active',
            sorter: true,
            filters: [
                { text: 'Ativo', value: true },
                { text: 'Inativo', value: false },
            ],
            onFilter: (value, record) => record.active === value,
            defaultSortOrder: getSortOrder('active', queryParams.sort),
            render: (active) => {
                let text = active ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionAuthorityGroups.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => navigate(`/perfis/editar/${record.uuid}`)}>
                    <div><EditOutlined /></div>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionAuthorityGroups.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const handleSubmit = async (values: { name: string; code: string }) => {
        const newParams: any = {
            name: values.name || undefined,
            code: values.code || undefined,
        };

        const search = [
            values.name ? `name;"${values.name}"` : null,
        ]
            .filter(Boolean)
            .join(' ');

        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sort || 'created,desc',
            search,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await perfisStore.getList(params);
    };

    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm(); // Limpa os valores do formulário
        setQueryParams({}); // Limpa a URL
        await perfisStore.getList(defaultparams);

        const newInitialValues = {
            name: '',
        };

        //@ts-ignore
        resetForm(newInitialValues);
    };


    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = async () => {
        const response = await perfisStore.deletePerfil(itemSelected.uuid);
        if (!response?.error) {
            await perfisStore.getList(defaultparams);
            setIsModalOpen(false);
        }
    };


    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await perfisStore.getList(params);
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const filters = buildParamsFromQuery(queryParams, mappings);

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort,
            search: filters.search || queryParams.search || '',
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
            search: params.search,
        });

        return params;
    };



    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };


    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => perfisStore.deletePerfilAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    setSelectionCheckbox([]);
                    message.error('Erro ao deletar um ou mais Perfis.');
                } else {
                    setSelectionCheckbox([]);
                    message.success('Todos os Perfis foram deletados com sucesso!');
                    await perfisStore.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar os Perfis.');
        }
    };

    const handleExport = async () => {
        const params = buildParamsFromQuery(queryParams, mappings);
        const exportParams = {
            ...params,
            size: 999999,
        }

        const dataExport = await perfisStore.getList(exportParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: Profile) => ({
                name: item.name,
                created: formatDateString(item.created),
                active: item.active ? "Sim" : "Não",
            }));

            exportToExcel(listExport, ["Nome", "Data de Criação", "Ativo"], "Tabela de Perfil.xlsx");
        }
    };

    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionAuthorityGroups.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <Permission permissionKeys={[PermissionAuthorityGroups.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Perfil" description="Tela de listagem de Perfils">
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (
                            <Form>
                                <div className="flex items-end w-full gap-4">
                                    <div className="w-full">
                                        <InputComponet label="Nome" name="name" id="name" />
                                    </div>
                                    <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                                        <Button className="w-full" onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                                        <Button className="w-full" htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6" description='Tabela de Perfis' actions={
                    <>
                        <Permission permissionKeys={[PermissionAuthorityGroups.DELETE]}><ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton></Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>

                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={perfilList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        rowSelection={rowSelection}
                        loading={loading}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            {isModalOpen && <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Perfil <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />}

            {isModalDeleteOpen && <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />}
        </>
    );
});

export default ListPerfil;