export function restructureLogList(data: any[]): any[] {
    return data.map((subArray: any) => ({
        userDetails: subArray[0],        // Dados do usuário
        idInfo: subArray[1],             // Informações de ID
        actionType: { type: subArray[2] } // Tipo de ação
    }));
}

const isEqual = (a: any, b: any): boolean => {
    if (a === b) {
        return true;
    }

    // Verifica se ambos são objetos e não são nulos
    if (a && b && typeof a === 'object' && typeof b === 'object') {
        if (Array.isArray(a) !== Array.isArray(b)) {
            return false;
        }

        const keysA = Object.keys(a);
        const keysB = Object.keys(b);

        // Verifica se ambos têm o mesmo número de chaves
        if (keysA.length !== keysB.length) {
            return false;
        }

        // Verifica se cada chave e valor são iguais recursivamente
        for (const key of keysA) {
            if (!isEqual(a[key], b[key])) {
                return false;
            }
        }

        return true;
    }

    return false;
};

export const compareValues = (data: any) => {
    if (data !== null) {
        const { oldValue, newValue } = data;

        const oldObject = oldValue ? JSON.parse(oldValue) : {};
        const newObject = newValue ? JSON.parse(newValue) : {};

        const differences = [];
        const allKeys = new Set([...Object.keys(oldObject), ...Object.keys(newObject)]);

        const convertTimestampToDate = (timestamp: number | undefined | null): string | null => {
            if (timestamp) {
                try {
                    const date = new Date(timestamp);

                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                } catch (error) {
                    console.error("Erro ao converter timestamp:", timestamp, error);
                    return null;
                }
            }
            return null;
        };

        for (const key of allKeys) {
            let oldVal = oldObject[key];
            let newVal = newObject[key];
            let oldValueIsComplex = false;
            let newValueIsComplex = false;

            // Converte os campos 'modified' e 'created' para data
            if (key === 'modified' || key === 'created') {
                oldVal = convertTimestampToDate(oldVal);
                newVal = convertTimestampToDate(newVal);
            }

            const differencesChanged = !isEqual(oldVal, newVal);

            // Detecta objetos ou arrays
            if (typeof oldVal === 'object' && oldVal !== null) {
                oldVal = '[Objeto/Array]';
                oldValueIsComplex = true;
            }

            if (typeof newVal === 'object' && newVal !== null) {
                newVal = '[Objeto/Array]';
                newValueIsComplex = true;
            }

            differences.push({
                field: key,
                oldValue: oldVal,
                newValue: newVal,
                changed: differencesChanged,
                oldValueIsComplex,
                newValueIsComplex,
                rawOldValue: oldObject[key], // Valor bruto
                rawNewValue: newObject[key], // Valor bruto
            });
        }

        return differences;
    }

    return [];
};