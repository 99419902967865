import { Button, Drawer, Form } from 'antd';
import { Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import productStore from '../../../../../stores/productStore';
import InputComponent from '../../../../atoms/InputComponet';
import ImageUploadComponent from '../../../../atoms/UploadComponent';
import { DrawerUploadImageSchema } from './schema';


interface DrawerImagemUploadProps {
    isModalOpen?: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DrawerImagemUpload: React.FC<DrawerImagemUploadProps> = ({ isModalOpen, setIsModalOpen }) => {
    const handleSubmit = async (values: any) => {
        const file = values.image[0];

        const createProps = {
            file,
            "@class": "br.com.stoom.kernel.model.persistence.File",
            metaTags: ['card'],
            ordering: values.ordering,
            path: file.preview,
            size: file.size,
            name: file.name,
            uuid: uuidv4(),
            new: true,
        };

        productStore.addImageUpload(createProps);
        setIsModalOpen(false);
    };

    const initialValuesForm = {
        ordering: '',
        image: '',
    };

    return (
        <Formik validationSchema={DrawerUploadImageSchema} initialValues={initialValuesForm}
            onSubmit={async (values, { resetForm }) => {
                await handleSubmit(values);
                resetForm();
            }}
            enableReinitialize>
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Cadastrar Imagem"
                    width={540}
                    onClose={() => {
                        resetForm();
                        setIsModalOpen(false);
                    }}
                    open={isModalOpen}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                            <Button type="primary" onClick={submitForm}>
                                Cadastrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex gap-4 flex-col">
                            <ImageUploadComponent name="image" label="Selecione a Imagem" />
                            <InputComponent label="Ordenação" name="ordering" id="ordering" />
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
};

export default DrawerImagemUpload;