import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import LogService from '../services/LogService';


class LogStore {
    loading: boolean = false;
    logList: any[] = [];
    logBannerFile: any[] = [];
    logBanner: any[] = [];

    //Campanha
    logCampaing: any[] = [];
    totalElementsCampaing: number = 0;
    pageCampaing: number = 0;
    sizeCampaing: number = 0;

    //Preço
    logCupom: any[] = [];
    totalElementsCupom: number = 0;
    pageCupom: number = 0;
    sizeCupom: number = 0;

    //Preço
    logPrices: any[] = [];
    totalElementsPrices: number = 0;
    pagePrices: number = 0;
    sizePrices: number = 0;

    //Products
    logProduct: any[] = [];
    totalElementsProducts: number = 0;
    pageProducts: number = 0;
    sizeProducts: number = 0;

    //Customer
    logUser: any[] = [];
    totalElementsUser: number = 0;
    pageUser: number = 0;
    sizeUser: number = 0;

    //Customer
    logCustomer: any[] = [];
    totalElementsCustomer: number = 0;
    pageCustomer: number = 0;
    sizeCustomer: number = 0;

    //Channel
    logChannel: any[] = [];
    totalElementsChannel: number = 0;
    pageChannel: number = 0;
    sizeChannel: number = 0;

    //Order
    logOrder: any[] = [];
    totalElementsOrder: number = 0;
    pageOrder: number = 0;
    sizeOrder: number = 0;

    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getListUser(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                const modifiedData = response?.data?.content.map((item: any) => {
                    const newValueObject = JSON.parse(item?.newValue ?? item?.oldValue);
                    return {
                        ...item,
                        name: newValueObject?.name
                    };
                });

                this.logUser = modifiedData;
                this.pageUser = response.data.number;
                this.totalElementsUser = response.data.totalElements;
                this.sizeUser = response.data.size;
            } else {
                this.logUser = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListProduct(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                const modifiedData = response?.data?.content.map((item: any) => {
                    const newValueObject = JSON.parse(item?.newValue ?? item?.oldValue);
                    return {
                        ...item,
                        name: newValueObject?.name
                    };
                });

                this.logProduct = modifiedData;
                this.pageProducts = response.data.number;
                this.totalElementsProducts = response.data.totalElements;
                this.sizeProducts = response.data.size;
            } else {
                this.logProduct = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListCampaing(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                const modifiedData = response?.data?.content.map((item: any) => {
                    const newValueObject = JSON.parse(item?.newValue ?? item?.oldValue);
                    return {
                        ...item,
                        name: newValueObject?.name
                    };
                });

                this.logCampaing = modifiedData;
                this.pageCampaing = response.data.number;
                this.totalElementsCampaing = response.data.totalElements;
                this.sizeCampaing = response.data.size;
            } else {
                this.logCampaing = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListCupom(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                const modifiedData = response?.data?.content.map((item: any) => {
                    const newValueObject = JSON.parse(item?.newValue ?? item?.oldValue);
                    return {
                        ...item,
                        name: newValueObject?.name
                    };
                });

                this.logCupom = modifiedData;
                this.pageCupom = response.data.number;
                this.totalElementsCupom = response.data.totalElements;
                this.sizeCupom = response.data.size;
            } else {
                this.logCupom = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListBannerFile(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logBannerFile = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logBannerFile = [];
            }
            this.loading = false;
        });

        return response;
    }


    async getListBanner(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logBanner = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logBanner = [];
            }
            this.loading = false;
        });

        return response;
    }


    async getListPrices(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                const modifiedData = response?.data?.content.map((item: any) => {
                    const newValueObject = JSON.parse(item?.newValue ?? item?.oldValue);
                    return {
                        ...item,
                        product: newValueObject?.product?.name
                    };
                });


                this.logPrices = modifiedData;
                this.pagePrices = response.data.number;
                this.totalElementsPrices = response.data.totalElements;
                this.sizePrices = response.data.size;
            } else {
                this.logPrices = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListChannel(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                const modifiedData = response?.data?.content.map((item: any) => {
                    const newValueObject = JSON.parse(item?.newValue ?? item?.oldValue);
                    return {
                        ...item,
                        name: newValueObject?.name
                    };
                });

                this.logChannel = modifiedData;
                this.pageChannel = response.data.number;
                this.totalElementsChannel = response.data.totalElements;
                this.sizeChannel = response.data.size;
            } else {
                this.logChannel = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListOrder(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        const modifiedData = response?.data?.content.map((item: any) => {
            const newValueObject = JSON.parse(item?.newValue ?? item?.oldValue);

            return {
                ...item,
                idOrder: newValueObject?.idOrder,
                contactName: newValueObject?.customer?.contactName,
            };
        });

        runInAction(() => {
            if (!response.error) {
                this.logOrder = modifiedData;
                this.pageOrder = response.data.number;
                this.totalElementsOrder = response.data.totalElements;
                this.sizeOrder = response.data.size;
            } else {
                this.logOrder = [];
            }
            this.loading = false;
        });

        return response;
    }


    async getListCustomer(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                const modifiedData = response?.data?.content.map((item: any) => {
                    const newValueObject = JSON.parse(item?.newValue ?? item?.oldValue);
                    return {
                        ...item,
                        firstName: newValueObject?.firstName,
                    };
                });

                this.logCustomer = modifiedData;
                this.pageCustomer = response.data.number;
                this.totalElementsCustomer = response.data.totalElements;
                this.sizeCustomer = response.data.size;
            } else {
                this.logCustomer = [];
            }
            this.loading = false;
        });

        return response;
    }
}

const logStore = new LogStore();
export default logStore;