import { message } from 'antd';
import { makeAutoObservable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import PerfilService from '../services/PerfilService';
import { PaginationParams } from '../types';
import { mapErrorMessage } from '../utils/mapErrorMensage';


class PerfisStore {
    loading: boolean = false;
    perfilList: any = [];
    perfil: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.perfil = undefined;
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await PerfilService.getList(params);

        if (!response.error) {
            this.perfilList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
        }
        this.loading = false;
        return response;
    }

    async getPerfilByUuid(uuid: string) {
        this.loading = true;
        const response = await PerfilService.getPerfilByUuid(uuid);

        if (!response.error) {
            this.perfil = response.data;
        }

        this.loading = false;
        return response.data;
    }

    async createPerfil(data: any) {
        this.loading = true;
        const response = await PerfilService.createPerfil(data);

        if (response.error) {
            message.error(mapErrorMessage(response?.details?.response?.data?.message));
            this.perfil = data;
        } else {
            message.success('Perfil cadastrado com sucesso!');
        }

        this.loading = false;
        return response;
    }


    async editPerfil(data: any, uuid: string | undefined) {
        this.loading = true;
        const response = await PerfilService.editPerfil(data, uuid);

        if (response.error) {
            message.error(mapErrorMessage(response?.details?.response?.data?.message));
            this.perfil = data;
        } else {
            message.success('Perfil editado com sucesso!');
        }

        this.loading = false;
        return response;
    }


    async deletePerfilAll(uuid: string) {
        this.loading = true;
        const response = await PerfilService.deletePerfil(uuid);
        this.perfil = response.data;
        this.loading = false;
        return response;
    }


    async deletePerfil(uuid: string) {
        this.loading = true;
        const response = await PerfilService.deletePerfil(uuid);

        if (response.error) {
            message.error(`Erro ao deletar Perfil`);
        } else {
            message.success('Perfil deletado com sucesso!');
        }

        this.perfil = response.data;
        this.loading = false;
        return response;
    }
}

const perfisStore = new PerfisStore();
export default perfisStore;