import { Button, Col, Row, Table, TableColumnsType } from "antd";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TEXT_BUTTON_HEADER_LIST } from "../../../config/config";
import { PermissionCustomer } from "../../../permissions/PermissionCustomer";
import customerStore from "../../../stores/customerStore";
import BreadCrumbComponent from "../../atoms/BreadCrumbComponent";
import ActionButton from "../../atoms/ButtonComponent";
import CheckboxComponent from "../../atoms/CheckboxComponent";
import DatePickerComponet from "../../atoms/DatePickerComponet";
import InputComponent from "../../atoms/InputComponet";
import InputPassword from "../../atoms/InputPassword";
import NotPermissionComponent from "../../atoms/NotPermissionComponent";
import SelectComponent from "../../atoms/SelectComponent";
import ContentBox from "../../molecules/ContentBox";
import LoadingComponent from "../../molecules/LoadingComponent";
import Permission from "../../molecules/Permission";
import { useAuth } from "../../protected/ProviderAuth";
import { validationSchema } from "./schema";

interface DataType {
  zipCode: string;
  street: string;
  block: string;
  number: string;
  state: string;
  city: string;
  key: React.Key;
}

const breadCrumb = [
  {
    title: <Link to="/dashboard">Dashboard</Link>,
  },
  {
    title: <Link to="/cliente">Listagem de Cliente</Link>,
  },
  {
    title: <p>Cadastro de Cliente</p>,
  },
];

const CreateCustomer: React.FC = observer(() => {
  const { uuid } = useParams<{ uuid: string }>();
  const { customer, loading, addressList } = customerStore;
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  useEffect(() => {
    const fetchPerfilData = async () => {
      if (uuid && hasPermission(PermissionCustomer.GET_UUID)) {
        await customerStore.getCustomerByUuid(uuid);
      }
    };
    fetchPerfilData();
    return () => {
      customerStore.reset();
    };
  }, []);

  const defaultGender = (value: string, entity: string) => {
    if (value === "MASCULINO") {
      return {
        label: "Masculino",
        value: "MASCULINO",
      };
    } else if (value === "FEMININO") {
      return {
        label: "Feminino",
        value: "FEMININO",
      };
    } else if (entity === "FISICA") {
      return {
        label: "Feminino",
        value: "FEMININO",
      };
    } else {
      return {
        label: "Outros",
        value: "OUTROS",
      };
    }
  };

  const defaultEntity = (value: string) => {
    if (value === "FISICA") {
      return {
        label: "Física",
        value: "FISICA",
      };
    } else {
      return {
        label: "Jurídica",
        value: "JURIDICA",
      };
    }
  };

  const initialValues = {
    firstName: customer?.firstName || "",
    lastName: customer?.lastName || "",
    email: customer?.user?.email || "",
    municipalRegistration: customer?.municipalRegistration || "",
    stateRegistration: customer?.stateRegistration || "",
    gender: defaultGender(customer?.gender, customer?.entity),
    document: customer?.document || "",
    mobile: customer?.mobile || "",
    exempt: customer?.exempt || "",
    birthdate: customer?.birthdate || "",
    entity: defaultEntity(customer?.entity),
  };

  const handleSubmit = async (values: {
    firstName: string;
    lastName: string;
    email: string;
    municipalRegistration: string;
    stateRegistration: string;
    gender: { label: string; value: string };
    document: string;
    mobile: string;
    exempt: string;
    birthdate: any;
    entity: { label: string; value: string };
    password?: string;
  }) => {
    let response;

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      municipalRegistration: values.municipalRegistration,
      stateRegistration: values.stateRegistration,
      gender: values.gender.value,
      document: values.document,
      mobile: values.mobile,
      exempt: values.exempt ? "ISENTO" : undefined,
      birthdate: values.birthdate.$d,
      entity: values.entity.value,
      user: {
        email: values.email,
        password: values.password,
      },
    };

    if (uuid) {
      response = await customerStore.editCustomer(payload, uuid);
    } else {
      response = await customerStore.createCustomer(payload);
    }

    if (!response.error) listRouter();
  };

  const listRouter = () => {
    navigate(`/cliente`);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "CEP",
      dataIndex: "zipCode",
      key: "zipCode",
    },
    {
      title: "Rua",
      dataIndex: "street",
      key: "street",
    },
    {
      title: "Bairro",
      dataIndex: "block",
      key: "block",
    },
    {
      title: "Número",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Cidade",
      dataIndex: "city",
      key: "city",
    },
  ];

  return (
    <>
      <BreadCrumbComponent
        breadCrumbList={breadCrumb}
        textButton={TEXT_BUTTON_HEADER_LIST}
        onClick={() => listRouter()}
        permission={PermissionCustomer.GET}
      />

      {loading && (
        <ContentBox
          className="mb-4 p-6"
          title="Cadastro de Clientes"
          description="Tela para cadastro de Clientes"
        >
          <LoadingComponent />
        </ContentBox>
      )}

      {!loading && (
        <Permission
          permissionKeys={[PermissionCustomer.POST, PermissionCustomer.PUT, PermissionCustomer.GET_UUID]}
          fallback={NotPermissionComponent()}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div>
                    <ContentBox className="mb-4 p-6" title="Cadastro de Clientes" description="Tela para cadastro de Clientes">
                      <div className="flex gap-3 mb-3">
                        <SelectComponent
                          label="Tipo de Cadastro*"
                          name="entity"
                          options={[
                            { label: "Física", value: "FISICA" },
                            { label: "Jurídica", value: "JURIDICA" },
                          ]}
                        />
                        <InputComponent
                          label={
                            values?.entity?.value === "FISICA"
                              ? "Nome*"
                              : "Razão Social*"
                          }
                          name="firstName"
                          id="firstName"
                        />
                        <InputComponent
                          label={
                            values?.entity?.value === "FISICA"
                              ? "Sobrenome"
                              : "Nome Fantasia"
                          }
                          name="lastName"
                          id="lastName"
                        />
                      </div>
                      <div className="flex gap-3 mb-3">
                        <InputComponent
                          disabled={uuid ? true : false}
                          label="E-mail*"
                          name="email"
                          id="email"
                        />
                        <InputComponent
                          disabled={uuid ? true : false}
                          label="Documento*"
                          name="document"
                          id="document"
                        />
                        {values?.entity?.value === "FISICA" && (
                          <DatePickerComponet
                            label="Data de Nascimento"
                            name="birthdate"
                            isRange={false}
                          />
                        )}
                        {values?.entity?.value !== "FISICA" && (
                          <InputComponent
                            label="Inscrição Municipal"
                            name="municipalRegistration"
                            id="municipalRegistration"
                          />
                        )}
                      </div>
                      <div className="flex gap-3 mb-3 items-center">
                        {values?.entity?.value !== "FISICA" && (
                          <>
                            <Col className="gutter-row" span={8}>
                              <InputComponent
                                label="Inscrição Estadual"
                                name="stateRegistration"
                                id="stateRegistration"
                                disabled={values?.exempt}
                              />
                            </Col>

                            <div>
                              <CheckboxComponent
                                label="Isento"
                                name="exempt"
                                id="exempt"
                                onChange={() => {
                                  setFieldValue("exempt", !values?.exempt);
                                  if (!values?.exempt) {
                                    setFieldValue("stateRegistration", "ISENTO");
                                  } else {
                                    setFieldValue("stateRegistration", undefined);
                                  }
                                }}
                                checked={values?.exempt}
                              />
                            </div>
                          </>
                        )}
                        {values?.entity?.value === "FISICA" && (
                          <SelectComponent
                            label="Gênero"
                            name="gender"
                            options={[
                              { label: "Feminino", value: "FEMININO" },
                              { label: "Masculino", value: "MASCULINO" },
                            ]}
                          />
                        )}
                        {!uuid && (
                          <>
                            <InputPassword
                              label="Senha"
                              name="password"
                              id="password"
                            />
                            <InputPassword
                              label="Confirmar Senha"
                              name="confirmPassword"
                              id="confirmPassword"
                            />
                          </>
                        )}
                      </div>
                      {values?.entity?.value === "FISICA" && (
                        <Row gutter={16}>
                          <Col className="gutter-row" span={8}>
                            <InputComponent
                              label="Celular"
                              name="mobile"
                              id="mobile"
                            />
                          </Col>
                        </Row>
                      )}
                    </ContentBox>
                  </div>
                  <div className="flex justify-end">
                    <Permission permissionKeys={[PermissionCustomer.PUT, PermissionCustomer.POST]} buttonType>
                      <ActionButton htmlType="submit" actionType="primary">{uuid ? "Atualizar" : "Cadastrar"}</ActionButton>
                    </Permission>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Permission>
      )}
      {uuid && (
        <Permission permissionKeys={[PermissionCustomer.POST, PermissionCustomer.PUT, PermissionCustomer.GET_UUID]}>
          <ContentBox
            className="mt-6 mb-4 p-6"
            title="Endereços"
            description="Endereços do Cliente"
          >
            <div className="flex items-end w-full gap-4 pb-4 items-center justify-end">
              <div className="flex gap-4">
                <Button className="button-export">Exportar para Excel</Button>
              </div>
            </div>

            <Table
              className="table-custom"
              columns={columns}
              dataSource={
                addressList &&
                addressList.map((item: any, index: number) => ({
                  ...item,
                  key: item.uuid || index,
                }))
              }
              loading={loading}
              size="middle"
              bordered={false}
            />
          </ContentBox>
        </Permission>
      )}
    </>
  );
});

export default CreateCustomer;
