import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { ColorPicker, message, Space, Table, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, defaultparamsExport, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionColor } from '../../../permissions/PermissionColor';
import colorStore from '../../../stores/colorStore';
import { Color } from '../../../types/Cores';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import { getStatusColor } from '../../../utils/statusColorMapper';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    active: boolean;
    hexColorCode: string;
    shortName: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

<Link to="/dashboard">Dashboard</Link>

// BreadCrumb
const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <p>Listagem de Cores</p>,
    },
]

const ListColor: React.FC = observer(() => {
    const { colorList, loading, page, totalElements, defaultparams, sort, size } = colorStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const listRouter = () => { navigate(`/cores/create`); }
    const navigate = useNavigate();
    const { hasPermission, isColumnVisible } = useAuth();

    useEffect(() => {
        const mappings = {
            page: 'page',
            size: 'size',
            sort: 'sort',
        };

        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionColor.GET)) {
                await colorStore.getList(params);

            }
        };
        fetchData();
    }, [hasPermission]);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Código hexadecimal',
            dataIndex: 'hexColorCode',
            key: 'hexColorCode',
            sorter: true,
            filters: getUniqueFilters(colorList, 'hexColorCode'),
            onFilter: (value, record) => record.hexColorCode.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('hexColorCode', queryParams.sort),
            render: (hexColorCode) => {
                return (
                    <ColorPicker
                        className="color-picker-disabled"
                        disabled
                        defaultValue={hexColorCode}
                        showText={(color) => <span>HEX: {hexColorCode}</span>}
                    />
                );
            },
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            filters: getUniqueFilters(colorList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
        },
        {
            title: 'Nome Curto',
            dataIndex: 'shortName',
            key: 'shortName',
            sorter: true,
            filters: getUniqueFilters(colorList, 'shortName'),
            onFilter: (value, record) => record.shortName.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('shortName', queryParams.sort),
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
            render: (text) => {
                return (
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Ativos',
            dataIndex: 'active',
            key: 'active',
            sorter: true,
            filters: [
                { text: 'Ativo', value: true },
                { text: 'Inativo', value: false },
            ],
            onFilter: (value, record) => record.active === value,
            defaultSortOrder: getSortOrder('active', queryParams.sort),
            render: (active) => {
                let text = active ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionColor.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => navigate(`/cores/create/${record.uuid}`)}>
                    <div><EditOutlined /></div>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionColor.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };

    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => colorStore.deleteColorAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    message.error('Erro ao deletar uma ou mais cores.');
                    setSelectionCheckbox([]);
                } else {
                    setSelectionCheckbox([]);
                    message.success('Todos as cores foram deletados com sucesso!');
                    await colorStore.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar as cores.');
        }
    };


    const handleDeleteItem = async () => {
        const response = await colorStore.deleteColor(itemSelected.uuid);
        if (!response?.error) {
            colorStore.getList(defaultparams);
            setIsModalOpen(false);
        }
    };

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await colorStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const currentPage = pagination.current ? pagination.current - 1 : 0; // Páginas começam do zero
        const pageSize = pagination.pageSize || queryParams.size || DEFAULT_PAGE_SIZE;
        const sortList = getSortField(sorter) || queryParams.sort || sort;

        const params = {
            size: pageSize,
            page: currentPage,
            sort: sortList || 'created,desc',
        };

        setQueryParams(params);
        return params;
    };


    const handleExport = async () => {
        const dataExport = await colorStore?.getList(defaultparamsExport);

        if (dataExport) {
            //@ts-ignore
            const listExport = dataExport?.data?.content.map((item: Color) => ({
                name: item.name,
                userName: item.shortName,
                hexColorCode: item.hexColorCode,
            }));

            exportToExcel(listExport, ["Name", "NomeCurto", "Código hexadecimal"], "Tabela de Cores.xlsx");
        }
    };

    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionColor.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <Permission permissionKeys={[PermissionColor.GET]} fallback={NotPermissionComponent()}>
                <ContentBox className="mb-4 p-6" title="Cores" description='Tela de listagem de Cores' actions={
                    <>
                        <Permission permissionKeys={[PermissionColor.DELETE]}><ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton></Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={colorList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        loading={loading}
                        rowSelection={rowSelection}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            {isModalOpen && <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir a Cor <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />}

            {isModalDeleteOpen && <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />}
        </>
    );
});

export default ListColor;