import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import SizeService from '../services/SizeService';
import { PaginationParams } from '../types';
import { message } from 'antd';
import { Size } from '../types/Size';
import { transformToValueLabel } from '../utils/functions';
import { mapErrorMessage } from '../utils/mapErrorMensage';


class SizeStore {
    loading: boolean = false;
    sizeList: Size[] = [];
    sizeSelectList: any;
    sort?: string = '';
    sizeProps?: Size | undefined;
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    reset() {
        this.sizeProps = undefined;
        this.sizeList = [];
        this.sizeSelectList = [];
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await SizeService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.sizeList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
                this.sort = params.sort;
                this.sizeSelectList = transformToValueLabel(response.data.content, 'uuid', 'code');
            }
            this.loading = false;
        });
        return response;
    }


    async createSize(size: Size) {
        this.loading = true;
        const response = await SizeService.createSize(size);

        runInAction(() => {
            if (response.error) {
                message.error(mapErrorMessage(response?.details?.response?.data?.message));
                this.sizeProps = size;
            } else {
                message.success('Tamanho cadastrado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }


    async editSize(size: Size, uuid: string | undefined) {
        this.loading = true;
        const response = await SizeService.editSize(size, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(mapErrorMessage(response?.details?.response?.data?.message));
                this.sizeProps = size;
            } else {
                message.success('Tamanho editado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }


    async getSizeByUuid(uuid: string) {
        this.loading = true;
        const response = await SizeService.getSizeByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar Tamanho`);
                return;
            }

            this.sizeProps = response.data;
            this.loading = false;
        });
        return response;
    }


    async deleteSize(uuid: string) {
        this.loading = true;
        const response = await SizeService.deleteSize(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar Tamanho`);
                return;
            } else {
                message.success('Tamanho deletado com sucesso!');
                this.sizeProps = undefined;
            }
            this.loading = false;
        });
        return response;
    }

    async deleteSizeAll(uuid: string) {
        this.loading = true;
        const response = await SizeService.deleteSize(uuid);

        runInAction(() => {
            if (!response.error) {
                this.sizeProps = undefined;
            }
            this.loading = false;
        });
        return response;
    }
}

const sizeStore = new SizeStore();
export default sizeStore;