import { Typography } from 'antd';
import { Field } from 'formik';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import './input.scss';

//@ts-ignore
interface CurrencyInputComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    value?: string | number;
    name: string; // Tornando o name obrigatório
    size?: 'large' | 'middle' | 'small';
    hidden?: boolean;
    currency?: string; // Tipo de moeda (ex: BRL, USD)
}

const CurrencyInputComponent: React.FC<CurrencyInputComponentProps> = ({
    label,
    hidden,
    value,
    name,
    size = 'large',
    disabled,
    placeholder,
    currency = 'R$',
    ...props
}) => (
    <div className={`flex flex-col w-full currency-input-container ${hidden ? 'hidden' : ''}`}>
        {label && <Typography.Title style={{ fontSize: "14px" }} level={5}>{label}</Typography.Title>}
        <Field name={name}>
            {({ field, form, meta }: any) => {
                const handleChange = (value: string | undefined) => {
                    // Atualiza o campo no Formik diretamente com o valor bruto
                    form.setFieldValue(name, value);
                };

                return (
                    <div className={meta.touched && meta.error ? 'error-border' : ''}>
                        {/* @ts-ignore */}
                        <CurrencyInput
                            id={`currency-input-${name}`}
                            name={name}
                            value={field.value} // Valor vindo do Formik
                            placeholder={placeholder}
                            decimalsLimit={2}
                            decimalSeparator=","
                            groupSeparator="."
                            prefix={`${currency} `}
                            disabled={disabled}
                            onValueChange={handleChange}
                            {...props}
                        />
                        {meta.touched && meta.error && (
                            <Typography.Text type="danger">{meta.error}</Typography.Text>
                        )}
                    </div>
                );
            }}
        </Field>
    </div>
);

export default CurrencyInputComponent;
