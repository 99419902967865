import { Input, Typography } from 'antd';
import { Field } from 'formik';
import React from 'react';
import './input.scss';

//@ts-ignore
interface InputPasswordProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    placeholder?: string;
    maxLength?: number;
    disabled?: boolean;
    showMaxLength?: boolean;
    name: string; // Tornando o name obrigatório
    size?: string;
    onChange?: (value: string) => void; // Nova prop
}

const InputPassword: React.FC<InputPasswordProps> = ({ label, onChange, showMaxLength, maxLength, name, size, disabled, placeholder, ...props }) => (
    <div className="flex flex-col w-full input-password">
        {label && <Typography.Title level={5}>{label}</Typography.Title>}
        <Field name={name}>
            {({ field, meta }: any) => {
                return (
                    <>
                        <Input.Password {...field}
                            maxLength={maxLength}
                            size={size ? size : "large"}
                            disabled={disabled}
                            placeholder={placeholder}
                            status={meta.touched && meta.error ? 'error' : ''}
                            onChange={(e) => {
                                field.onChange(e); // Garante que o Formik será notificado
                                onChange && onChange(e.target.value); // Chama o onChange passado como prop
                            }}
                        />
                        {meta.touched && meta.error && (
                            <Typography.Text type="danger">{meta.error}</Typography.Text>
                        )}
                    </>
                );
            }}
        </Field>
    </div>
);

export default InputPassword;
