import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionColor } from '../../../permissions/PermissionColor';
import colorStore from '../../../stores/colorStore';
import unsavedStore from '../../../stores/unsavedStore';
import { Color } from '../../../types/Cores';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ColorPickerComponent from '../../atoms/ColorPickerComponent';
import InputComponet from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import SwitchComponent from '../../atoms/SwitchComponent';


const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <Link to="/cores">Listagem de Cores</Link>,
    },
    {
        title: <p>Cadastro de Cores</p>,
    },
]

const CreateColor: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { color, loading } = colorStore;
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            if (uuid && hasPermission(PermissionColor.GET_UUID)) {
                colorStore.getColorByUuid(uuid);
            }
        };

        getInformation();

        return () => {
            colorStore.reset();
        };
    }, [uuid]);

    const initialValues: Color = {
        name: color?.name || '',
        shortName: color?.shortName || '',
        uuid: color?.uuid || '',
        hexColorCode: color?.hexColorCode || '',
        active: color?.active || false,
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: Color) => {
        let response;

        const payload = {
            ...values,
            active: values.active || false,
        };

        if (uuid) {
            response = await colorStore.editColor(payload, uuid);
        } else {
            response = await colorStore.createColor(payload);
        }

        if (!response.error) listRouter();
    };


    const listRouter = () => {
        navigate(`/cores`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionColor.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Cor" description='Tela para cadastro de Cores'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionColor.POST, PermissionColor.PUT, PermissionColor.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ dirty }) => {
                            useEffect(() => {
                                unsavedStore.updateUnsavedChanges(dirty);
                            }, [dirty]);

                            return (
                                <Form>
                                    <ContentBox
                                        className="mb-4 p-6"
                                        title="Cadastro de Cor"
                                        description="Tela para cadastro de Cores" actions={
                                            <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
                                        }
                                    >
                                        <div className="flex w-full gap-4 pb-4">
                                            <InputComponet label="Nome" name="name" id="name" />
                                            <InputComponet label="Nome curto" name="shortName" id="shortName" />
                                        </div>

                                        <div className="flex w-full gap-4 pb-4">
                                            <ColorPickerComponent
                                                label="Código hexadecimal de cor*"
                                                name="hexColorCode"
                                                id="hexColorCode"
                                            />
                                        </div>
                                    </ContentBox>

                                    <div className="flex justify-end">
                                        <Permission permissionKeys={[PermissionColor.PUT, PermissionColor.POST]} buttonType>
                                            <ActionButton htmlType="submit" actionType="primary">
                                                {uuid ? 'Atualizar' : 'Cadastrar'}
                                            </ActionButton>
                                        </Permission>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Permission>
            }
        </>
    );
});

export default CreateColor;