import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionMaterials } from '../../../permissions/PermissionMaterials';
import materialStore from '../../../stores/materialStore';
import { Material } from '../../../types/Material';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import unsavedStore from '../../../stores/unsavedStore';

const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <Link to="/material">Listagem de Material</Link>,
    },
    {
        title: <p>Cadastro de Material</p>,
    },
]

const CreateMaterial: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { material, loading } = materialStore;
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            if (uuid && hasPermission(PermissionMaterials.GET_UUID)) {
                materialStore.getMaterialByUuid(uuid);
            }
        };

        getInformation();

        return () => {
            materialStore.reset();
        };
    }, []);


    const initialValues: Material = {
        code: material?.code || '',
        description: material?.description || '',
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: Material) => {
        let response;

        if (uuid) {
            response = await materialStore.editMaterial(values, uuid);
        } else {
            response = await materialStore.createMaterial(values);
        }

        if (!response.error) listRouter();
    };


    const listRouter = () => {
        navigate(`/material`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionMaterials.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Material" description='Tela para cadastro de Material'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionMaterials.POST, PermissionMaterials.PUT, PermissionMaterials.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ dirty }) => {
                            useEffect(() => {
                                unsavedStore.updateUnsavedChanges(dirty);
                            }, [dirty]);

                            return (
                                <Form>
                                    <ContentBox className="mb-4 p-6" title="Cadastro de Material" description='Tela para cadastro de Material'>
                                        <div className="flex w-full gap-4 pb-4">
                                            <InputComponet label="Código" name="code" id="code" disabled={uuid ? true : false} />
                                            <InputComponet label="Descrição" name="description" id="description" />
                                        </div>
                                    </ContentBox>

                                    <div className="flex justify-end">
                                        <Permission permissionKeys={[PermissionMaterials.PUT, PermissionMaterials.POST]} buttonType>
                                            <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                        </Permission>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Permission>
            }
        </>
    );
});

export default CreateMaterial;