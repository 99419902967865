import { message, Tabs, TabsProps } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionProducts } from '../../../permissions/PermissionProducts';
import authStore from '../../../stores/authStore';
import colorStore from '../../../stores/colorStore';
import materialStore from '../../../stores/materialStore';
import productStore from '../../../stores/productStore';
import propertiesStore from '../../../stores/propertiesStore';
import sizeStore from '../../../stores/sizeStore';
import voltagemStore from '../../../stores/voltagemStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import TablePrice from '../../organisms/ProductComponents/TablePrice';
import TableSpecification from '../../organisms/ProductComponents/TableSpecification';
import TableStock from '../../organisms/ProductComponents/TableStock';
import TableUnits from '../../organisms/ProductComponents/TableUnits';
import UplodFileProduct from '../../organisms/ProductComponents/UplodFileProduct';
import { useAuth } from '../../protected/ProviderAuth';
import ProdutoInformation from './ProdutoInformation';
import { validationSchema } from './schema';
import VariationSelect from './VariationSelect';
import { PermissionProperties } from '../../../permissions/PermissionProperties';
import { PermissionColor } from '../../../permissions/PermissionColor';
import { PermissionSize } from '../../../permissions/PermissionSize';
import { PermissionMaterials } from '../../../permissions/PermissionMaterials';
import { PermissionVoltage } from '../../../permissions/PermissionVoltage';
import unsavedStore from '../../../stores/unsavedStore';

const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <Link to="/produtos-variacao">Listagem de Produtos (Variação)</Link>,
    },
    {
        title: <p>Cadastro de Produto Variaçao</p>,
    },
]

const CreateProductVariation: React.FC = observer(() => {
    const [autocompleteOptions, setAutocompleteOptions] = useState<any>([]);
    const { uuid } = useParams<{ uuid: string }>();
    const { product, listPrice } = productStore;
    const navigate = useNavigate();
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [loading, setLoading] = useState(true);
    const { hasPermission } = useAuth();
    const { merchant } = authStore;

    const defaultparams = {
        size: 9999,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    useEffect(() => {
        const getInformation = async () => {
            setLoading(true);

            if (uuid && hasPermission(PermissionProducts.GET_UUID)) {
                await productStore.getProductByUuid(uuid);
            }

            if (hasPermission(PermissionColor.GET)) {
                await colorStore.getList(defaultparams)
            }

            if (hasPermission(PermissionProperties.GET)) {
                await propertiesStore.getList({ size: 999999, page: 0, sort: 'created,desc' });
            }

            if (hasPermission(PermissionSize.GET)) {
                await sizeStore.getList(defaultparams)
            }

            if (hasPermission(PermissionMaterials.GET)) {
                await materialStore.getList(defaultparams)
            }

            if (hasPermission(PermissionVoltage.GET)) {
                await voltagemStore.getList(defaultparams)
            }

            setLoading(false);
        };

        getInformation();

        return () => {
            // Remover ou comentar isso se você não quiser resetar ao desmontar
            productStore.reset();
        };
    }, [uuid]);


    const initialValues = {
        //Informação Inicial
        active: product?.active || false,
        name: product?.name || '',
        shortName: product?.shortName || '',
        barcode: product?.barcode || '',
        videoPath: product?.videoPath || '',
        slug: product?.slug || '',
        skuCode: product?.skuCode || '',
        metaCanonical: product?.metaCanonical || '',
        metaKeywords: product?.metaKeywords || '',
        metaTitle: product?.metaTitle || '',
        metaDescription: product?.metaDescription || '',
        shortDescription: product?.shortDescription || '',
        description: product?.description || '',

        //Infomação de Ficha Tecnica
        netWeight: product?.netWeight || '',
        weight: product?.weight || '',
        height: product?.height || '',
        length: product?.length || '',
        shippingWeight: product?.shippingWeight || '',
        shippingHeight: product?.shippingHeight || '',
        shippingWidth: product?.shippingWidth || '',
        width: product?.width || '',
        cubage: product?.cubage || '',
        ordering: '',
        path: '',
        baseProduct: { value: product?.baseProduct?.name, label: '' },
        material: { value: product?.material?.uuid, label: '' },
        size: { value: product?.size?.uuid, label: '' },
        voltage: { value: product?.voltage?.uuid, label: '' },
        color: { value: product?.color?.uuid, label: '' },
    };



    const handleSubmit = async (values: typeof initialValues) => {
        setLoading(true);
        let response;

        const createProps = {
            '@class': "br.com.stoom.tresCoracoes.model.TresCoracoesProduct",
            ...product,
            ...values,
            currency: "BRL",
            barCode: '34234',
            baseProduct: {
                uuid: values?.baseProduct?.label || product?.baseProduct?.uuid
            },
            unitOfMeasurementList: product.unitOfMeasurementList,
            merchant,
            color: values?.color?.value ? {
                uuid: values?.color?.value,
                name: values?.color?.label
            } : undefined,
            material: values?.material?.value ? {
                uuid: values?.material?.value,
                code: values?.material?.label
            } : undefined,
            size: values?.size?.value ? {
                uuid: values?.size?.value,
                code: values?.size?.label
            } : undefined,
            voltage: values?.voltage?.value ? {
                uuid: values?.voltage?.value,
                code: values?.voltage?.label
            } : undefined,
            parent: false,
            searchable: false,
            shippingHeight: 1,
            shippingLength: 1,
            shippingWeight: 1,
            shippingWidth: 1,
            height: 1,
            length: 1,
            weight: 1,
            width: 1,
            netWeight: 1,
            cubage: 1
        };

        if (uuid) {
            response = await productStore.editProduct(createProps, uuid);
        } else {
            response = await productStore.createProduct(createProps);
        }

        setLoading(false);
        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/produtos-variacao`);
    }

    const onChange = (key: string) => {
        setActiveTabKey(key);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <ProdutoInformation autocompleteOptions={autocompleteOptions} setAutocompleteOptions={setAutocompleteOptions} />,
        },
        {
            key: '2',
            label: 'Imagens',
            children: <UplodFileProduct />,
        },
        {
            key: '3',
            label: 'Variação',
            children: <VariationSelect />,
        },
        {
            key: '4',
            label: 'Especificação',
            children: <TableSpecification />,
        },
        {
            key: '5',
            label: 'Tabela de Unidades',
            children: <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg'><TableUnits unitList={product.unitOfMeasurementList} /></ContentBox>,
        },
        {
            key: '6',
            label: 'Tabela de Preço',
            children: <ContentBox className="mb-4 p-6"><TablePrice unitList={product?.unitOfMeasurementList} priceList={listPrice} /></ContentBox>,
        },
        {
            key: '7',
            label: 'Estoque',
            children: <TableStock />,
        },
    ];

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionProducts.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading ? (
                <ContentBox className="mb-4 p-6" title="Cadastro de Produto Variação" description="Tela para cadastro de Produto Variação">
                    <LoadingComponent />
                </ContentBox>
            ) : (
                <Permission
                    permissionKeys={[PermissionProducts.POST, PermissionProducts.PUT, PermissionProducts.GET_UUID]}
                    fallback={NotPermissionComponent()}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ validateForm, dirty }) => {
                            useEffect(() => {
                                unsavedStore.updateUnsavedChanges(dirty);
                            }, [dirty]);

                            return (
                                <Form>
                                    <Tabs
                                        className="mb-0"
                                        type="card"
                                        activeKey={activeTabKey}
                                        onChange={onChange}
                                        items={items}
                                    />
                                    <div className="flex justify-end">
                                        <Permission permissionKeys={[PermissionProducts.PUT, PermissionProducts.POST]} buttonType>
                                            <ActionButton onClick={async () => {
                                                const formErrors = await validateForm();

                                                if (Object.keys(formErrors).length > 0) {
                                                    setActiveTabKey('1');
                                                    message.error(`Há campos que não foram preenchidos`);
                                                }
                                            }} htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                        </Permission>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Permission>
            )}
        </>
    );
});

export default CreateProductVariation;