import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import BannerApi from '../services/BannerApi';
import { PaginationParams } from '../types';
import { transformToUploadFile, transformToValueLabel } from '../utils/functions';
import authStore from './authStore';

class BannerStore {
    loading: boolean = false;
    bannerList: any = [];
    bannerListSelected: any = [];
    banner: any = {
        files: [],
    };
    arrayImageUrl: any = [];
    previewImage: any = [];
    filesList: any = [];
    sort: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.banner = undefined;
        this.arrayImageUrl = [];
        this.previewImage = {
            files: [],
        };
        this.banner = {
            files: [],
        };
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await BannerApi.getList(params);

        runInAction(() => {
            if (!response.data.error) {
                this.bannerList = response.data.content;
                this.bannerListSelected = transformToValueLabel(response.data.content, 'uuid', 'nameTag');
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            }
            this.loading = false;
        });

        return response;
    }


    async getBannerByUuid(uuid: string) {
        this.loading = true;
        const response = await BannerApi.getBannerByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar Institucional`);
                return;
            }

            this.filesList = transformToUploadFile(response.data.files);
            this.arrayImageUrl = response.data.files;
            this.previewImage = response.data;
            this.banner = response.data;
            this.loading = false;
        });
        return response?.data;
    }

    async createBanner(data: any) {
        const { merchant } = authStore;
        this.loading = true;
        await this.saveImageUrl();

        const bodyBanner = JSON.stringify({
            ...this.banner,
            ...data,
            merchant,
        });

        const response = await BannerApi.createBanner(bodyBanner);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao criar Banner`);
            } else {
                message.success('Banner cadastrado com sucesso!');
            }

            this.loading = false;
        });
        return response;
    }


    async addBannerFile(data: any, preview: any) {
        // Garante que arrayImageUrl está inicializado
        if (!this.arrayImageUrl) {
            this.arrayImageUrl = [];
        }

        // Garante que previewImage.files está inicializado
        if (!this.previewImage) {
            this.previewImage = { files: [] };
        }
        if (!this.previewImage.files) {
            this.previewImage.files = [];
        }

        // Adiciona os itens
        this.arrayImageUrl.push(data);
        this.previewImage.files.push(preview);
    }

    async editBanner(data: any, uuid: string | undefined) {
        this.loading = true;
        await this.saveImageUrl();
        const { merchant } = authStore;

        const bodyBanner = JSON.stringify({
            ...this.banner,
            ...data,
            merchant,
        });

        const response = await BannerApi.editBanner(bodyBanner, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao editar Banner`);
            } else {
                message.success('Banner editado com sucesso!');
            }
        });

        this.loading = false;
        return response;
    }

    async saveImageUrl() {
        if (this.arrayImageUrl.length > 0) {
            for (const newImage of this.arrayImageUrl) {
                const file = newImage.file;
                const formData = new FormData();
                formData.append('file', file);

                if (!newImage['@class']) {
                    const response = await BannerApi.uploadBannerFile(formData);

                    if (!response.error) {
                        const banner = {
                            file: {
                                "uuid": response.uuid
                            },
                            name: newImage.name,
                            ordering: newImage.ordering,
                            subDescription: newImage.subDescription,
                            description: newImage.description,
                            link: newImage.link,
                            initialDate: newImage.initialDate,
                            finalDate: newImage.finalDate,
                        }
                        this.banner.files.push(banner);
                    }
                }

            }
        }
    }


    async deleteBannerAll(uuid: string) {
        this.loading = true;
        const response = await BannerApi.deleteBanner(uuid);

        runInAction(() => {
            this.banner = response.data;
            this.loading = false;
        });
        return response;
    }


    async deleteBanner(uuid: string) {
        this.loading = true;
        const response = await BannerApi.deleteBanner(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar Banner`);
            } else {
                message.success('Banner deletado com sucesso!');
                const filterBannerDelete = this.bannerList.filter((item: any) => item.uuid === uuid)
                this.bannerList = filterBannerDelete;
            }

            this.loading = false;
        });
        return response;
    }


    async updateValue(key: string, value: string) {
        const newProps = { ...this.banner, tag: value };
        this.banner = newProps;
    }

    async deleteFile(uuid: string) {
        const findImage = this.banner.files.find((item: any) => item.uuid === uuid);
        const removeBanner = this.banner.files.filter((item: any) => item.uuid !== uuid);
        const removePreview = this.previewImage.files.filter((item: any) => item.uuid !== uuid);
        const removeArrayImageUrl = this.arrayImageUrl.filter((item: any) => item.uuid !== uuid);

        this.banner.files = removeBanner;
        this.arrayImageUrl = removeArrayImageUrl;
        this.previewImage.files = removePreview;

        if (findImage) {
            const bannerUuid = this.banner.uuid;
            const response = await BannerApi.deleteFileBanner(bannerUuid, uuid);

            if (!response.error) {
                message.success('Imagem deletada com sucesso!');
            } else {
                message.error(`Erro ao deletar Imagem`);
            }
        }
    }
}

const bannerStore = new BannerStore();
export default bannerStore;