import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionOrder } from '../../../permissions/PermissionOrder';
import orderStore from '../../../stores/orderStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ClientDetailsInformation from '../../organisms/ClientComponents/ClientDetailsInformation';
import TableOrderCustomer from '../../organisms/ClientComponents/TableOrderCustomer';
import { useAuth } from '../../protected/ProviderAuth';
import OrderDetailsPayment from './components/OrderDetails';

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/pedidos">Pedidos</a>,
    },
    {
        title: <p>Detalhes do pedido</p>,
    },
]

const OrderDetails: React.FC = observer(() => {
    const { order, loading, defaultparams } = orderStore;
    const { customerUuid } = useParams<{ customerUuid: string }>();
    const { orderUuid } = useParams<{ orderUuid: string }>();
    const [orderStatus, setOrderStatus] = useState<any>();
    const { hasPermission } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (hasPermission(PermissionOrder.GET_UUID)) {
                const response = await orderStore.getOrder(orderUuid, customerUuid);


                if (response?.data?.statusChanges.length > 0) {
                    setOrderStatus(response?.data?.statusChanges);
                } else {
                    const statusChanges = [
                        {
                            newStatus: response?.data.status
                        }
                    ];
                    setOrderStatus(statusChanges);
                }

                orderStore.getListByCustomer(customerUuid, defaultparams);
            }
        };

        fetchData();
    }, []);


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Detalhes do Pedido',
            //@ts-ignore
            children: <OrderDetailsPayment orderStatus={orderStatus} />,
        },
        {
            key: '2',
            label: 'Informação do Cliente',
            children: <ClientDetailsInformation loading={loading} customer={order?.customer} />,
        },
        {
            key: '3',
            label: 'Pedidos Cliente',
            children: <TableOrderCustomer uuid={customerUuid} />,
        },
    ];

    const listRouter = () => {
        navigate(`/pedidos`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionOrder.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            <div className="flex gap-4 ">
                <div className="w-full">
                    <Tabs className="mb-0" type="card" defaultActiveKey="1" items={items} />
                </div>
            </div>
        </>
    );
});

export default OrderDetails;