export const validatePasswordCriteria = (password: string | undefined, confirmPassword: string | undefined) => {
    if (password) {
        const validations = {
            hasUppercase: /[A-Z]/.test(password),         // Contém letra maiúscula
            hasLowercase: /[a-z]/.test(password),         // Contém letra minúscula
            hasNumber: /\d/.test(password),              // Contém número
            hasSpecialChar: /[@#$%^&*!]/.test(password),  // Contém caractere especial
            hasMinLength: password.length >= 10,         // Comprimento mínimo de 10 caracteres
        };

        return {
            ...validations,
            passwordsMatch: password === confirmPassword, // Verifica se as senhas são iguais
            isValid: Object.values(validations).every(Boolean) && password === confirmPassword, // Verifica se todas as condições são verdadeiras e as senhas coincidem
        };
    }
};