import { DeleteOutlined } from '@ant-design/icons';
import { Table, TableColumnsType } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionProperties } from '../../../permissions/PermissionProperties';
import authStore from '../../../stores/authStore';
import propertiesStore from '../../../stores/propertiesStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import InputNumberComponet from '../../atoms/InputNumberComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';
import DrawerProperties from './components/DrawerProperties';
import { validationSchema } from './schema';
import PermissionComponent from '../../atoms/PermissionComponent';


const breadCrumb = [
    {
        title: <Link to="/dashboard">Dashboard</Link>,
    },
    {
        title: <Link to="/especificacao">Listagem de Especificação</Link>,
    },
    {
        title: <p>Cadastro de Especificação</p>,
    },
]

interface DataType {
    uuid: any;
    key: React.Key;
    nameTag: string;
    responsive: boolean;
    tag: string;
}

const CreateProperties: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { propertie, loading } = propertiesStore;
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const { hasPermission, isColumnVisible } = useAuth();
    const { merchant } = authStore;

    useEffect(() => {
        if (uuid && hasPermission(PermissionProperties.GET_UUID)) {
            propertiesStore.getPropertieByUuid(uuid);
        }

        return () => {
            propertiesStore.reset();
        };
    }, []);


    const initialValues = {
        ordering: propertie?.ordering || '',
        className: propertie?.className || '',
    };


    const handleSubmit = async (values: { ordering: string; className: string }) => {
        let response;

        const createProps = {
            ...propertie,
            ...values,
            display: true,
            merchant: merchant,
            filter: true,
        };

        delete createProps['@class'];

        if (uuid) {
            response = await propertiesStore.editPropertie(createProps, uuid);
        } else {
            response = await propertiesStore.createPropertie(createProps);
        }

        if (!response.error) listRouter();
    };


    const listRouter = () => {
        navigate(`/especificacao`);
    }

    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Ordenação',
            dataIndex: 'ordering',
            key: 'ordering',
        },
        {
            title: 'Valor da Propriedade',
            dataIndex: 'propertyValue',
            key: 'propertyValue',
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionProperties.DELETE_CLASSES),
            render: (text, record) => (
                <div onClick={() => showModal(record)} className="cursor-pointer"><DeleteOutlined /></div>
            ),
        },
    ];


    const handleDeleteItem = async () => {
        propertiesStore.deletePropertiesValue(itemSelected);
        setIsModalOpen(false);
    };


    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };


    const sortedData = propertie && [...propertie?.values].sort((a, b) => {
        if (a.ordering < b.ordering) return -1;
        if (a.ordering > b.ordering) return 1;
        return 0;
    });

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionProperties.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Especificação" description='Tela para cadastro de Especificação'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionProperties.POST, PermissionProperties.PUT, PermissionProperties.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <div>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={validationSchema}
                        >
                            <Form>
                                <ContentBox className="mb-4 p-6" title="Cadastro de Especificação" description='Tela para cadastro de Especificação'>
                                    <div className="flex w-full gap-4 pb-4 grid grid-cols-12">
                                        <div className="w-full col-span-3">
                                            <InputNumberComponet label="Ordenação*" name="ordering" id="ordering" />
                                        </div>
                                        <div className="w-full col-span-9">
                                            <InputComponet label="Nome" name="className" id="className" />
                                        </div>
                                    </div>
                                </ContentBox>


                                <ContentBox className="mb-4 p-6" title="Cadastro de Propriedades" description='Tela para cadastro de Especificação' actions={
                                    <Permission permissionKeys={[PermissionProperties.POST_CLASSES]}>
                                        <div className="flex gap-2">
                                            <ActionButton icon actionType="create" onClick={() => setIsDrawerOpen(true)}>Cadastrar</ActionButton>
                                        </div>
                                    </Permission>
                                }>
                                    <Table
                                        className="table-custom"
                                        columns={columns}
                                        dataSource={sortedData}
                                        loading={loading}
                                        size="middle"
                                        bordered={false}
                                        showSorterTooltip={{ target: 'sorter-icon' }}
                                    />
                                </ContentBox>

                                <div className="flex justify-end">
                                    <Permission permissionKeys={[PermissionProperties.PUT, PermissionProperties.POST]} buttonType>
                                        <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                    </Permission>
                                </div>
                            </Form>
                        </Formik >
                    </div>
                </Permission>
            }

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir a Especificação <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />

            <DrawerProperties setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen} />
        </>
    );
});

export default CreateProperties;