import { Button, Modal, Table, TableColumnsType, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { getActionColor, getActionLog } from '../../../../../utils/statusLogMapper';
import { getStatusColor } from '../../../../../utils/statusColorMapper';
import { EyeOutlined } from '@ant-design/icons';
import ReactDiffViewer from 'react-diff-viewer'
import { compareValues } from '../../../../../utils/logFunctions';


interface ModalLogComparativoProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    itemSelected?: any;
}


interface DataType {
    newValueIsComplex: React.JSX.Element;
    oldValue: string | number | boolean | null | undefined;
    oldValueIsComplex: React.JSX.Element;
    changed: boolean | React.Key;
    active: boolean;
    hexColorCode: string;
    shortName: string;
    name: string;
    uuid: string;
    key: React.Key;
}

const ModalLogComparativo: React.FC<ModalLogComparativoProps> = observer(({ isModalOpen, setIsModalOpen, itemSelected }) => {
    const [viewJson, setViewJson] = useState(false);
    const [jsonCompare, setJsonCompare] = useState<any>();
    const tableItens = compareValues(itemSelected).map((item: any) => ({
        ...item,
        oldValue: item.oldValue || '-', // Garante que valores nulos sejam tratados
        newValue: item.newValue || '-',
        field: item.field || 'N/A',
    }));

    const isBoolean = (value: string | number | boolean | null | undefined) => {
        if (typeof value === 'boolean') {
            return value.toString(); // true -> "true", false -> "false"
        }

        if (typeof value === 'string') {
            const lowerValue = value.toLowerCase();
            if (lowerValue === 'true' || lowerValue === 'false') {
                return lowerValue; // Mantém a string em minúsculas
            }
        }

        // Valores como null, undefined ou 0 podem ser tratados de forma específica aqui
        return value !== null && value !== undefined && value !== 0 ? value.toString() : '-';
    };

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Campo',
            dataIndex: 'field',
            key: 'field',
            onCell: () => ({
                style: { backgroundColor: '#fafafa' },
            }),
        },
        {
            title: 'Antes',
            dataIndex: 'oldValue',
            key: 'oldValue',
            render: (oldValue) => {
                return (
                    <p>{isBoolean(oldValue)}</p>
                );
            },
        },
        {
            title: 'Depois',
            dataIndex: 'newValue',
            key: 'newValue',
            render: (newValue) => {
                return (
                    <p>{isBoolean(newValue)}</p>
                );
            },
        },
        {
            title: 'Alterado',
            dataIndex: 'changed',
            key: 'changed',
            filters: [
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
            ],
            onFilter: (value, record) => record.changed === value,
            render: (changed) => {
                let text = changed ? 'Sim' : 'Não';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 80,
            render: (_, record) => (
                <span>
                    {(record.oldValueIsComplex || record.newValueIsComplex) && (
                        <EyeOutlined
                            style={{ marginLeft: 8, cursor: 'pointer' }}
                            onClick={() => {
                                setViewJson(true);
                                setJsonCompare(record);
                            }}
                        />
                    )}
                </span>
            ),
        },
    ];


    const formattedOldValue = JSON.stringify(jsonCompare?.rawOldValue, null, 2);
    const formattedNewValue = JSON.stringify(jsonCompare?.rawNewValue, null, 2);

    return (
        <>
            <Modal cancelText="Fechar" width={1000} title="Detalhamento de Alterações" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={[
                <Button key="cancel" type='primary' onClick={() => setIsModalOpen(false)}>
                    Fechar
                </Button>,
            ]}>

                {!viewJson &&
                    <Table
                        title={() =>
                            <div className="flex justify-between">
                                <div>
                                    <p>Usuário: <b>{itemSelected?.modifiedBy}</b></p>
                                </div>
                                <div>
                                    <Tag color={getActionColor(itemSelected?.operation)}> <p>{getActionLog(itemSelected?.operation)}</p></Tag>
                                </div>
                            </div>
                        }
                        className="table-custom"
                        columns={columns}
                        dataSource={tableItens.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        bordered
                        size="middle"
                        pagination={false}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                        scroll={{ y: 350 }}
                    />
                }

                {viewJson &&
                    <div className="flex flex-col">
                        <div className="border p-2 px-3 flex justify-end">
                            <Tag className="cursor-pointer p-1 px-3" onClick={() => setViewJson(false)} color="red">Fechar</Tag>
                        </div>

                        <div className="border" style={{ maxWidth: '952px', overflowX: 'auto', maxHeight: '389px' }}>
                            <ReactDiffViewer
                                oldValue={formattedOldValue}
                                newValue={formattedNewValue}
                                splitView={true}
                            />
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
});

export default ModalLogComparativo;